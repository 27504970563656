import { RootState } from '../../../config';
import { FleetTripData } from '../../../../@types';

export const selectCares = (state: RootState): FleetTripData[] | null =>
  state.userData.cares.entities;

export const selectDisplayCares = (state: RootState): FleetTripData[] | null =>
  state.userData.cares.display;

export const selectSelectedCare = (state: RootState): any =>
  state.userData.cares.selected;

export const selectFinishServiceTripViolations = (state: RootState): string[] =>
  state.userData.cares.finishTripViolations;

export const selectCaresLoading = (state: RootState): boolean =>
  state.userData.cares.loading;
