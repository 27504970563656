import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ProfileEditDrawerBody.styles';
import TabLayout from '../../components/shared/TabLayout';
import { selectSelectedProfile } from '../../redux/store/userData/profiles';
import ProfileInfo from './ProfileInfo';
import { openModal } from '../../redux/store/ui/common';
import ProfileImages from './ProfileImages';
import { selectAuth } from '../../redux/store/userData/user/selectors';

const ProfileEditDrawerBody = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const selectedProfile = useSelector(selectSelectedProfile);

  const tabs = [
    { title: 'Vehicle Data', content: <ProfileInfo /> },
    { title: 'Images', content: <ProfileImages /> },
  ];

  const handleEditProfile = (e: any) => {
    e.preventDefault();
    dispatch(openModal('updateProfileModal'));
  };

  return (
    <>
      <div className="profile-edit-drawer-body">
        <div className="profile-name-wrapper">
          <p className="name" title={selectedProfile?.name}>
            {selectedProfile?.name?.length > 20
              ? `${selectedProfile!.name!.slice(0, 20)}...`
              : selectedProfile!.name}
          </p>
          {user?.permissions?.fleetManager && (
            <img
              className="profile-name-edit-button"
              src={'/images/drawer/edit.svg'}
              alt="edit"
              onClick={handleEditProfile}
            />
          )}
        </div>
        <TabLayout tabs={tabs} keyName="profile-edit-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ProfileEditDrawerBody;
