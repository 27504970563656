import { Reducer } from '../../../../@types';
import { initialState, FiltersState, APIFilterParams } from './initialState';
import { FilterTypes } from '../../../../@types/enums/redux/store';
import getTargets from '../../../../components/Filter/helpers/getTargets';
import { Target } from '../../../../components/Filter/@types';

const mapFilterStateToAPIParams = (
  filterState: any,
  targets: Target[]
): any => {
  const apiParams: APIFilterParams = {};

  targets
    ?.filter((target) => target.isAPI && filterState[target.value])
    ?.forEach((target) => {
      if (
        target.type === 'date' &&
        Array.isArray(filterState[target.value]) &&
        filterState[target.value].length > 0
      ) {
        const dateInput = filterState[target.value][0];
        const dateRange = dateInput.split('_');

        if (dateInput.startsWith('after_') || dateInput.startsWith('before_')) {
          const mode = dateRange[0];
          const date = Date.parse(dateRange[1]);

          if (mode === 'after' && date) {
            apiParams[target.queryNameFrom || target.value] = new Date(
              date
            ).toISOString();
          } else if (mode === 'before' && date) {
            apiParams[target.queryNameTo || target.value] = new Date(
              date
            ).toISOString();
          }
        } else {
          // Handle the "fromDate_toDate" format
          const fromDate = Date.parse(dateRange[0]);
          const toDate = Date.parse(dateRange[1]);

          if (target.queryNameFrom && fromDate) {
            apiParams[target.queryNameFrom] = new Date(fromDate).toISOString();
          }

          if (target.queryNameTo && toDate) {
            apiParams[target.queryNameTo] = new Date(toDate).toISOString();
          }
        }
      } else {
        const key = target?.queryName || target.value;
        let value = [...filterState[target.value]];

        if (value[0] !== undefined) {
          apiParams[key] = value;
        }
      }
    });

  return Object.values(apiParams).flat().length > 0 ? apiParams : null;
};

const createUpdatedState = (
  state: FiltersState,
  vm: string,
  key: string,
  value: any[],
  targets: Target[]
) => {
  const updated = {
    ...state,
    [vm]: {
      // @ts-ignore
      ...state[vm],
      [key]: value,
    },
  };

  return {
    ...updated,
    [vm]: {
      ...updated[vm],
    },
    apiParams: mapFilterStateToAPIParams(updated[vm], targets),
  };
};

export const filterReducer: Reducer<FiltersState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case FilterTypes.OPEN_FILTER_PANEL:
      return {
        ...state,
        isPanelOpen: true,
      };
    case FilterTypes.CLOSE_FILTER_PANEL:
      return {
        ...state,
        isPanelOpen: false,
        target: initialState.target,
        targetType: initialState.targetType,
      };
    case FilterTypes.SET_TARGET:
      return {
        ...state,

        target: action.payload.target,

        targetType: action.payload.targetType,
      };
    case FilterTypes.ADD_FILTER: {
      const { viewMode: vm, key, value } = action.payload;
      const targets = getTargets(vm);

      let valueInArray;
      if (typeof value === 'string' && value.includes(',')) {
        valueInArray = value.split(',');
      } else {
        valueInArray = Array.isArray(value) ? value : [value];
      }

      return createUpdatedState(state, vm, key, valueInArray, targets);
    }

    case FilterTypes.REMOVE_FILTER: {
      const { viewMode: vm, key, value } = action.payload;
      const targets = getTargets(vm);
      // @ts-ignore
      const targetValue = state[vm][key];

      if (targetValue && Array.isArray(targetValue) && targetValue.length > 1) {
        const newValue = targetValue.filter((v) => {
          if (Array.isArray(value)) {
            return !value.includes(v);
          } else {
            return v !== value;
          }
        });

        return createUpdatedState(state, vm, key, newValue, targets);
      } else {
        const updatedState = { ...state } as any;
        delete updatedState[vm][key];
        return {
          ...updatedState,
          apiParams: mapFilterStateToAPIParams(updatedState[vm], targets),
        };
      }
    }
    default:
      return state;
  }
};
