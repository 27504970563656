/* eslint-disable react-hooks/rules-of-hooks */
import { ViewMode } from './useViewMode';
import { selectCarsLoading } from '../redux/store/userData/cars/selectors';
import { selectAreasLoading } from '../redux/store/appData/areas/selectors';
import { selectClaimsLoading } from '../redux/store/userData/claims/selectors';
import { selectCaresLoading } from '../redux/store/userData/cares/selectors';
import {
  selectBookingsLoading,
  selectTripLoading,
} from '../redux/store/userData/trips/selectors';
import { selectReceiptsLoading } from '../redux/store/userData/receipts/selectors';
import { selectUsersLoading } from '../redux/store/userData/users';
import { selectCirclesLoading } from '../redux/store/userData/circles';
import { selectLoadingAdmins } from '../redux/store/userData/admins';
import { selectCategoryLoading } from '../redux/store/userData/categories/selectors';
import { selectProfilesLoading } from '../redux/store/userData/profiles';
import { selectPricignsLoading } from '../redux/store/appData/pricings/selectors';
import { useSelector } from 'react-redux';
import { selectPrivacyPolicyLoading } from '../redux/store/appData/privacyPolicy';

const loadingSelectorsMap = {
  [ViewMode.VEHICLE]: selectCarsLoading,
  [ViewMode.AREA]: selectAreasLoading,
  [ViewMode.CLAIM]: selectClaimsLoading,
  [ViewMode.CARE]: selectCaresLoading,
  [ViewMode.SHARE]: selectTripLoading,
  [ViewMode.RECEIPTS]: selectReceiptsLoading,
  [ViewMode.USERS]: selectUsersLoading,
  [ViewMode.CIRCLE]: selectCirclesLoading,
  [ViewMode.BOOKING]: selectBookingsLoading,
  [ViewMode.ADMINS]: selectLoadingAdmins,
  [ViewMode.CATEGORIES]: selectCategoryLoading,
  [ViewMode.PROFILE]: selectProfilesLoading,
  [ViewMode.PRICING]: selectPricignsLoading,
  [ViewMode.PRIVACY_POLICY]: selectPrivacyPolicyLoading,
};

export const useLoadingViewMode = (viewMode) =>
  useSelector(loadingSelectorsMap[viewMode] || (() => null));
