export const optionsForUserStatus: Array<string> = ['active', 'blocked'];

export const optionsForCWStatus: Array<string> = ['open', 'verified', 'failed'];

export const optionsForCardStatus: Array<string> = [
  'open',
  'listed',
  'verified',
  'paused',
  'deleted',
];

export const optionsForUserType: Array<string> = ['user', 'org'];

export const optionsForBlockReason: Array<string> = [
  'fraud',
  'duplicate',
  'other',
];

export const optionsForBlockReasonDetails: Array<string> = [
  'duplicateLicense',
  'duplicateIdentityDocument',
  'duplicateUserIdentity',
  'fraudLicense',
  'fraudIdentity',
  'fraudPayment',
  'fraudOther',
  'creditCheck',
  'watchlisted',
  'poorDriver',
  'poorDebtor',
];
