import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from 'antd';
import DrawerModal from '../../../components/shared/DrawerModal';
import styles from './UserStatusModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import { useIntl } from 'react-intl';
import {
  blockUser,
  unblockUser,
  selectSelectedUser,
} from '../../../redux/store/userData/users';
import SelectorLayout from '../../shared/SelectorLayout';

type State = {
  nextStatus: string | undefined;
  blockReason?: string;
  blockReasonDetail?: string;
  noteText: string;
};

const USER_ACCOUNT_STATUS_OPTIONS = ['active', 'blocked'];
const USER_BLOCK_REASON_OPTIONS = ['fraud', 'duplicate', 'other'];
const USER_BLOCK_REASON_DETAIL_OPTIONS = [
  'fraudIdentity',
  'fraudLicense',
  'fraudPayment',
  'fraudOther',
  'duplicateLicense',
  'duplicateIdentityDocument',
  'duplicateUserIdentity',
  'duplicateUserMarkedAsFraud',
  'creditCheck',
  'watchlisted',
  'poorDriver',
  'poorDebtor',
];

const UserStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);

  const initialState = {
    nextStatus: selectedUser?.accountStatus,
    blockReason: selectedUser?.blockReason,
    blockReasonDetail: selectedUser?.blockReasonDetail,
    noteText: null,
  };
  const [state, setState] = useState<State>(initialState);
  const { nextStatus, blockReason, blockReasonDetail, noteText } = state;
  const [isClearComment, setClearComment] = useState(false);
  const visibleModal = useSelector(getIsVisibleModal('UserStatusModal'));
  const currentStatus = selectedUser?.accountStatus;
  const mapOptions = (options: any) =>
    options.map((option: any) => ({
      label: intl.formatMessage({
        id: `fleetadmin.user.blocked.${option}`,
      }),
      value: option,
    }));
  const statusOptions = USER_ACCOUNT_STATUS_OPTIONS.map((option: any) => ({
    label: intl.formatMessage({
      id: `fleetadmin.status.${option}`,
    }),
    value: option,
  }));
  const blockReasonOptions = mapOptions(USER_BLOCK_REASON_OPTIONS);
  const blockReasonDetailOptions = mapOptions(
    USER_BLOCK_REASON_DETAIL_OPTIONS.filter((detail) =>
      blockReason === 'other'
        ? !detail.includes('duplicate') &&
          !detail.includes('fraud') &&
          !['creditCheck', 'watchlisted'].includes(detail)
        : detail.includes(blockReason)
    )
  );

  const onSelectUserStatus = (value: any) => {
    setState({ ...state, nextStatus: value });
  };

  const onSelectReason = (value: any) => {
    setState({ ...state, blockReason: value });
  };

  const onSelectReasonDetails = (value: any) => {
    setState({ ...state, blockReasonDetail: value });
  };

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (nextStatus == 'blocked') {
      dispatch(
        blockUser(selectedUser.id, {
          blockReason,
          ...(blockReasonDetail && { blockReasonDetail }),
          ...(noteText !== null && { noteText }),
        })
      );
    } else {
      dispatch(
        unblockUser(selectedUser.id, noteText !== null ? { noteText } : {})
      );
    }
    dispatch(closeModal('UserStatusModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('UserStatusModal'));
  };

  const onClickClearComment = (e) => {
    setClearComment(e.target.checked);
    setState({ ...state, noteText: '' });
  };

  useEffect(() => {
    setState(initialState);
    setClearComment(false);
  }, [visibleModal, selectedUser]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('UserStatusModal'));
        }}
      >
        <div className="update-car-status-modal">
          <div className="status-selector-wrapper">
            <p className="label">Status</p>
            <SelectorLayout
              keyPrefix="care-search"
              placeholder={intl.formatMessage({
                id: 'fleetadmin.user.status.placeholder',
              })}
              options={statusOptions}
              value={nextStatus}
              onSelect={onSelectUserStatus}
            />
          </div>
          {nextStatus === 'blocked' && (
            <>
              <div className="reason-selector-wrapper">
                <p className="label">
                  {intl.formatMessage({
                    id: 'fleetadmin.user.status.reason',
                  })}
                </p>
                <SelectorLayout
                  keyPrefix="care-search"
                  placeholder={intl.formatMessage({
                    id: 'fleetadmin.user.status.reason',
                  })}
                  options={blockReasonOptions}
                  value={blockReason}
                  onSelect={onSelectReason}
                />
              </div>
              {blockReasonDetailOptions?.length > 0 && (
                <div className="reason-selector-wrapper">
                  <p className="label">
                    {intl.formatMessage({
                      id: 'fleetadmin.user.status.reason.details',
                    })}
                  </p>
                  <SelectorLayout
                    keyPrefix="care-search"
                    placeholder={intl.formatMessage({
                      id: 'fleetadmin.user.status.reason.details',
                    })}
                    options={blockReasonDetailOptions}
                    value={blockReasonDetail}
                    onSelect={onSelectReasonDetails}
                  />
                </div>
              )}
            </>
          )}

          <Checkbox
            style={{ marginBottom: 8, marginTop: 8 }}
            value={isClearComment}
            onChange={onClickClearComment}
          >
            {intl.formatMessage({
              id: 'fleetadmin.car.status.clear.note',
            })}
          </Checkbox>
          <div className="status-selector-wrapper">
            <p className="label">
              {intl.formatMessage({
                id: `fleetadmin.user.note.label`,
              })}
            </p>
            <textarea
              className="comment-area"
              placeholder={intl.formatMessage({
                id: `fleetadmin.user.note.placeholder`,
              })}
              value={noteText}
              onChange={(e) => setState({ ...state, noteText: e.target.value })}
              draggable={false}
              disabled={isClearComment}
            />
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={
              (nextStatus === 'blocked' && !blockReason) ||
              (currentStatus === nextStatus &&
                currentStatus !== 'blocked' &&
                nextStatus !== 'blocked' &&
                !isClearComment &&
                noteText === null)
            }
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserStatusModal;
