import { createAction, createErrorAction } from '../../../helpers';
import {
  ErrorResponse,
  DamageEntityData,
  FleetRentalData,
} from '../../../../@types';
import { ClaimsTypes } from '../../../../@types/enums';

export const ClaimsByCarRequest = () =>
  createAction(ClaimsTypes.CLAIMS_BY_CAR_REQUEST);

export const ClaimsByCarSuccess = () =>
  createAction(ClaimsTypes.CLAIMS_BY_CAR_SUCCESS);

export const ClaimsByCarFailure = () =>
  createAction(ClaimsTypes.CLAIMS_BY_CAR_FAILURE);

export const claimsRequest = () => createAction(ClaimsTypes.CLAIMS_REQUEST);

export const assignableClaimTripsRequest = () =>
  createAction(ClaimsTypes.ASSIGNABLE_CLAIM_REQUEST);

export const claimsSuccess = (payload: DamageEntityData[]) =>
  createAction(ClaimsTypes.CLAIMS_SUCCESS, payload);

export const assignableClaimTripsSuccess = (payload: FleetRentalData[]) =>
  createAction(ClaimsTypes.ASSIGNABLE_CLAIM_SUCCESS, payload);

export const claimsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ClaimsTypes.CLAIMS_FAILURE, error);

export const setDisplayClaims = (payload: DamageEntityData[]) =>
  createAction(ClaimsTypes.SET_DISPLAY_CLAIMS, payload);

export const selectClaim = (payload: DamageEntityData | null) =>
  createAction(ClaimsTypes.SELECT_CLAIM, payload);

export const updateClaimRequest = () =>
  createAction(ClaimsTypes.UPDATE_CLAIM_REQUEST);

export const updateClaimSuccess = (payload: DamageEntityData) =>
  createAction(ClaimsTypes.UPDATE_CLAIM_SUCCESS, payload);

export const updateClaimFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ClaimsTypes.UPDATE_CLAIM_FAILURE, error);

export const assignableClaimTripsFailure = <E extends ErrorResponse>(
  error: E
) => createErrorAction(ClaimsTypes.ASSIGNABLE_CLAIM_FAILURE, error);

export const setTotalDamages = (payload: number) =>
  createAction(ClaimsTypes.SET_TOTAL_DAMAGES, payload);

export const claimRequest = () => createAction(ClaimsTypes.CLAIM_REQUEST);

export const claimSuccess = (payload: DamageEntityData[]) =>
  createAction(ClaimsTypes.CLAIM_SUCCESS, payload);

export const claimFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ClaimsTypes.CLAIM_FAILURE, error);
