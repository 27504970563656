import { GAdminUserDataOutput } from '../redux/store/userData/users';
import { formatDuration } from 'date-fns';
import { store } from '../redux/config';
import { de, enUS } from 'date-fns/locale';
import { parse } from 'iso8601-duration';

export const getPaymentIcon = (payment) =>
  `/images/list-action-item/${
    payment.methodType == 'payIn'
      ? 'paymentPayIn.png'
      : payment.methodType == 'paypal'
      ? 'paymentPaypal.svg'
      : payment.methodType == 'sepa'
      ? 'paymentSepa.svg'
      : payment?.card?.variant === 'MasterCard'
      ? 'paymentMastercard.svg'
      : 'paymentVisa.svg'
  }`;

export const getStatusColor = (status) => {
  switch (status) {
    case 'open':
    case 'unknown':
    case 'EXPIRED':
      return 'grey';
    case 'listed':
    case 'submitted':
    case 'paused':
    case 'pending':
    case 'EXPIRING':
    case 'DRAFT':
      return 'orange';
    case 'verified':
    case 'approved':
    case 'active':
    case 'success':
    case 'done':
    case 'true':
      return 'green';
    case 'primary':
    case 'PUBLISHED':
    case 'imported':
      return 'blue';
    case 'failed':
    case 'deleted':
    case 'blocked':
    case 'rejected':
    case 'archived':
    case 'error':
    case 'false':
      return 'red';
    default:
      return 'grey';
  }
};

export const isVerifiedUser = (user: GAdminUserDataOutput, isOrg: boolean) =>
  user?.emailVerified &&
  user?.verified?.phoneNumber &&
  user?.addressVerified &&
  (isOrg || user?.verified?.identityDocument) &&
  (isOrg || user?.verified?.drivingLicense) &&
  user?.creditworthinessApproved;

export const findDuplicationReason = (user1, user2) => {
  if (
    user1?.identityDocument?.number &&
    user1?.identityDocument?.number === user2?.identityDocument?.number
  ) {
    return 'fleetadmin.users.same.id';
  }

  if (
    user1?.license?.number &&
    user1?.license?.number === user2?.license?.number
  ) {
    return 'fleetadmin.users.same.dl';
  }

  if (
    user1?.firstName &&
    user1?.lastName &&
    user1?.dateOfBirth &&
    user1?.firstName === user2?.firstName &&
    user1?.lastName === user2?.lastName &&
    user1?.dateOfBirth === user2?.dateOfBirth
  ) {
    return 'fleetadmin.users.same.identity';
  }

  return null;
};

export const getFilteredColumns = (columns, displayColumns) =>
  columns.filter((column) =>
    // @ts-ignore
    displayColumns?.includes(
      typeof column?.dataIndex === 'object'
        ? `${column?.dataIndex?.[0]}.${column?.dataIndex?.[1]}`
        : column?.dataIndex
    )
  );

// Parse ISO 8601 duration to extract number and unit
export const parseISO8601Duration = (isoString) => {
  if (isoString) {
    const parsed = parse(isoString);

    if (parsed.years) return { number: parsed.years, unit: 'years' };
    if (parsed.months) return { number: parsed.months, unit: 'months' };
    if (parsed.weeks) return { number: parsed.weeks, unit: 'weeks' };
    if (parsed.days) return { number: parsed.days, unit: 'days' };
    if (parsed.hours) return { number: parsed.hours, unit: 'hours' };
    if (parsed.minutes) return { number: parsed.minutes, unit: 'minutes' };
    if (parsed.seconds) return { number: parsed.seconds, unit: 'seconds' };
  }
  return { number: '', unit: 'days' }; // Default if no valid unit found
};

// Construct an ISO 8601 duration string from number and unit
export const formatToISO8601Duration = (number, unit) => {
  const durationObject = {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  durationObject[unit] = number;

  let durationString = 'P';
  if (durationObject.years) durationString += `${durationObject.years}Y`;
  if (durationObject.months) durationString += `${durationObject.months}M`;
  if (durationObject.weeks) durationString += `${durationObject.weeks}W`;
  if (durationObject.days) durationString += `${durationObject.days}D`;

  if (
    durationObject.hours ||
    durationObject.minutes ||
    durationObject.seconds
  ) {
    durationString += 'T';
    if (durationObject.hours) durationString += `${durationObject.hours}H`;
    if (durationObject.minutes) durationString += `${durationObject.minutes}M`;
    if (durationObject.seconds) durationString += `${durationObject.seconds}S`;
  }

  return durationString;
};

// New utility function to parse the full ISO duration
const parseFullISODuration = (isoString) => {
  const parsed = parse(isoString);
  return {
    years: parsed.years || 0,
    months: parsed.months || 0,
    days: parsed.days || 0,
    hours: parsed.hours || 0,
    minutes: parsed.minutes || 0,
    seconds: parsed.seconds || 0,
  };
};

// Function to format the ISO 8601 duration into a readable string using date-fns
export const formatISODuration = (value) => {
  const userLocale = store?.getState()?.userData?.user?.locale;
  const locale = userLocale?.includes('de') ? de : enUS;
  const duration = parseFullISODuration(value);

  // Format using date-fns based on user's locale
  return formatDuration(duration, { locale });
};

export const getLocalizedDurationLabel = (unit) => {
  const userLocale = store?.getState()?.userData?.user?.locale;
  const locale = userLocale?.includes('de') ? de : enUS;

  const durationObject = {
    years: { years: 2 },
    months: { months: 2 },
    days: { days: 2 },
    hours: { hours: 2 },
    minutes: { minutes: 2 },
    seconds: { seconds: 2 },
  };

  // Format the duration using date-fns to get the localized string
  const formatted = formatDuration(durationObject[unit], { locale });

  // Split the formatted string and return only the unit part
  return formatted.split(' ')[1];
};

export const truncateText = (text: string, maxChar = 20) =>
  text?.length > maxChar ? `${text?.slice(0, maxChar)}...` : text;
