import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import DrawerModal from '../../../../components/shared/DrawerModal';
import styles from './PurposeStatusModal.styles';
import {
  getIsVisibleModal,
  closeModal,
} from '../../../../redux/store/ui/common';
import { useIntl } from 'react-intl';
import SelectorLayout from '../../../shared/SelectorLayout';
import {
  createPurpose,
  fetchActivationEventsIfNeeded,
  fetchGroupsIfNeeded,
  selectActivationEvents,
  selectFieldGroups,
  selectCollectionNames,
  selectSelectedPrivacyPolicy,
  selectSelectedPurpose,
  updatePurpose,
  fetchCollectionNamesIfNeeded,
} from '../../../../redux/store/appData/privacyPolicy';
import { ProcessingPurposeInput } from '../../../../@types';
import {
  formatToISO8601Duration,
  getLocalizedDurationLabel,
  parseISO8601Duration,
} from '../../../../utils';

const durationUnits = [
  { label: 'Days', value: 'days' },
  // { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
  { label: 'Hours', value: 'hours' },
  { label: 'Minutes', value: 'minutes' },
  { label: 'Seconds', value: 'seconds' },
];

const PurposeStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedPurpose = useSelector(selectSelectedPurpose);
  const selectedPolicy = useSelector(selectSelectedPrivacyPolicy);
  const collectionNames = useSelector(selectCollectionNames);
  const activationEvents = useSelector(selectActivationEvents);
  const groups = useSelector(selectFieldGroups);

  const [selectedName, setSelectedName] = useState(selectedPurpose?.name || '');
  const [selectedCollectionName, setSelectedCollectionName] = useState(
    selectedPurpose?.collectionName || ''
  );
  const [selectedStartDate, setSelectedStartDate] = useState(
    selectedPurpose?.activationEventKey || ''
  );
  const [selectedMinDurationNumber, setSelectedMinDurationNumber] =
    useState('');
  const [selectedMaxDurationNumber, setSelectedMaxDurationNumber] =
    useState('');

  const [selectedMinDurationUnit, setSelectedMinDurationUnit] =
    useState('days');
  const [selectedMaxDurationUnit, setSelectedMaxDurationUnit] =
    useState('days');

  const [selectedGroups, setSelectedGroups] = useState(
    selectedPurpose?.fieldGroupKeys || []
  );
  const [selectedLegalBasis, setSelectedLegalBasis] = useState(
    selectedPurpose?.legalBasis || ''
  );
  const visibleModal = useSelector(getIsVisibleModal('PurposeStatusModal'));

  const onChangeName = (e) => setSelectedName(e.target.value);

  const onChangeMinDurationNumber = (e) => {
    if (e.target.value >= 0) setSelectedMinDurationNumber(e.target.value);
  };
  const onChangeMaxDurationNumber = (e) => {
    if (e.target.value >= 0) setSelectedMaxDurationNumber(e.target.value);
  };

  const onChangeMinDurationUnit = (value) => setSelectedMinDurationUnit(value);
  const onChangeMaxDurationUnit = (value) => setSelectedMaxDurationUnit(value);

  const handleOnCancel = (e) => {
    e.preventDefault();
    dispatch(closeModal('PurposeStatusModal'));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (selectedPurpose) {
      const payload = {} as ProcessingPurposeInput;

      if (selectedPurpose?.name !== selectedName) {
        payload.name = selectedName;
      }
      if (selectedPurpose?.collectionName !== selectedCollectionName) {
        payload.collectionName = selectedCollectionName;
      }
      if (selectedPurpose?.activationEventKey !== selectedStartDate) {
        payload.activationEventKey = selectedStartDate;
      }
      const minIsoDuration = formatToISO8601Duration(
        selectedMinDurationNumber,
        selectedMinDurationUnit
      );
      if (selectedPurpose?.minDuration !== minIsoDuration) {
        payload.minDuration = minIsoDuration;
      }

      const maxIsoDuration = formatToISO8601Duration(
        selectedMaxDurationNumber,
        selectedMaxDurationUnit
      );
      if (selectedPurpose?.maxDuration !== maxIsoDuration) {
        payload.maxDuration = maxIsoDuration;
      }
      if (selectedPurpose?.fieldGroupKeys !== selectedGroups) {
        payload.fieldGroupKeys = selectedGroups;
      }
      if (
        selectedLegalBasis !== '' &&
        selectedPurpose?.legalBasis !== selectedLegalBasis
      ) {
        payload.legalBasis = selectedLegalBasis;
      }
      dispatch(updatePurpose(selectedPurpose?.id, payload));
    } else {
      const minIsoDuration = formatToISO8601Duration(
        selectedMinDurationNumber,
        selectedMinDurationUnit
      );
      const maxIsoDuration = formatToISO8601Duration(
        selectedMaxDurationNumber,
        selectedMaxDurationUnit
      );
      const payload = {
        name: selectedName,
        activationEventKey: selectedStartDate,
        ...(selectedMinDurationNumber && { minDuration: minIsoDuration }),
        ...(selectedMaxDurationNumber && { maxDuration: maxIsoDuration }),
        fieldGroupKeys: selectedGroups,
        legalBasis: selectedLegalBasis,
        policyId: selectedPolicy.id,
        brandId: selectedPolicy.brandId,
        ...(selectedMaxDurationNumber && {
          auditRetentionPeriod: maxIsoDuration,
        }),
        collectionName: selectedCollectionName,
      } as ProcessingPurposeInput;
      dispatch(createPurpose(payload));
    }
    dispatch(closeModal('PurposeStatusModal'));
  };

  useEffect(() => {
    setSelectedName(selectedPurpose?.name || '');
    setSelectedName(selectedPurpose?.collectionName || '');
    setSelectedStartDate(selectedPurpose?.activationEventKey || '');
    setSelectedGroups(selectedPurpose?.fieldGroupKeys || []);
    setSelectedLegalBasis(selectedPurpose?.legalBasis || '');

    const { number: minNumber, unit: minUnit } = parseISO8601Duration(
      selectedPurpose?.minDuration || ''
    );
    setSelectedMinDurationNumber(minNumber.toString());
    setSelectedMinDurationUnit(minUnit);

    const { number: maxNumber, unit: maxUnit } = parseISO8601Duration(
      selectedPurpose?.maxDuration || ''
    );
    setSelectedMaxDurationNumber(maxNumber.toString());
    setSelectedMaxDurationUnit(maxUnit);
  }, [visibleModal, selectedPurpose]);

  useEffect(() => {
    if (visibleModal) {
      dispatch(fetchCollectionNamesIfNeeded());
      dispatch(fetchActivationEventsIfNeeded());
      dispatch(fetchGroupsIfNeeded());
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => dispatch(closeModal('PurposeStatusModal'))}
      >
        <div className="purpose-status-modal">
          <div className="status-selector-wrapper">
            <div className="purpose-modal-inputs">
              <div className="label">
                {intl.formatMessage({ id: `fleetadmin.purpose.name` })}
              </div>
              <Input
                value={selectedName}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.purpose.name`,
                })}
                onChange={onChangeName}
              />
            </div>

            <div className="purpose-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: `fleetadmin.purpose.collectionName`,
                })}
              </p>
              <SelectorLayout
                keyPrefix="care-search"
                placeholder={intl.formatMessage({
                  id: 'fleetadmin.purpose.collectionName',
                })}
                options={collectionNames?.map((name) => ({
                  label: intl.formatMessage({
                    id: `privacy.collectionName.${name}`,
                  }),
                  value: name,
                }))}
                value={selectedCollectionName}
                onSelect={setSelectedCollectionName}
              />
            </div>

            <div className="purpose-modal-inputs">
              <p className="label">
                {intl.formatMessage({ id: `fleetadmin.purpose.startdate` })}
              </p>
              <SelectorLayout
                keyPrefix="care-search"
                placeholder={intl.formatMessage({
                  id: 'fleetadmin.purpose.startdate',
                })}
                options={activationEvents?.map((event) => ({
                  label: intl.formatMessage({ id: `${event.key}` }),
                  value: event.key,
                }))}
                value={selectedStartDate}
                onSelect={(v) => {
                  setSelectedStartDate(v);
                  setSelectedGroups([]);
                }}
              />
            </div>

            <div className="purpose-modal-inputs">
              <div className="label">
                {intl.formatMessage({ id: `fleetadmin.purpose.minduration` })}
              </div>
              <div style={{ display: 'flex' }}>
                <Input
                  type="number"
                  value={selectedMinDurationNumber}
                  placeholder={intl.formatMessage({ id: 'number of units' })}
                  onChange={onChangeMinDurationNumber}
                  style={{ width: '50%' }}
                  min={0}
                />
                <SelectorLayout
                  keyPrefix="duration"
                  placeholder="Select unit"
                  options={durationUnits.map((unit) => ({
                    label: getLocalizedDurationLabel(unit.value),
                    value: unit.value,
                  }))}
                  value={selectedMinDurationUnit}
                  onSelect={onChangeMinDurationUnit}
                  style={{ width: '50%', marginLeft: 12 }}
                />
              </div>
            </div>

            <div className="purpose-modal-inputs">
              <div className="label">
                {intl.formatMessage({ id: `fleetadmin.purpose.maxduration` })}
              </div>
              <div style={{ display: 'flex' }}>
                <Input
                  type="number"
                  value={selectedMaxDurationNumber}
                  placeholder={intl.formatMessage({ id: 'number of units' })}
                  onChange={onChangeMaxDurationNumber}
                  style={{ width: '50%' }}
                  min={0}
                />
                <SelectorLayout
                  keyPrefix="duration"
                  placeholder="Select unit"
                  options={durationUnits.map((unit) => ({
                    label: getLocalizedDurationLabel(unit.value),
                    value: unit.value,
                  }))}
                  value={selectedMaxDurationUnit}
                  onSelect={onChangeMaxDurationUnit}
                  style={{ width: '50%', marginLeft: 12 }}
                />
              </div>
            </div>

            <div className="purpose-modal-inputs">
              <p className="label">
                {intl.formatMessage({ id: 'fleetadmin.purpose.groups' })}
              </p>
              <SelectorLayout
                keyPrefix="care-search"
                placeholder={intl.formatMessage({
                  id: 'fleetadmin.purpose.groups',
                })}
                options={groups
                  ?.filter(
                    (group) =>
                      group?.supportedActivationEvents?.includes(
                        selectedStartDate
                      ) && group?.collectionName === selectedCollectionName
                  )
                  ?.sort((a, b) =>
                    intl
                      .formatMessage({ id: `${a.key}` })
                      .localeCompare(intl.formatMessage({ id: `${b.key}` }))
                  )
                  .map((event) => ({
                    label: intl.formatMessage({ id: `${event.key}` }),
                    value: event.key,
                  }))}
                value={selectedGroups}
                mode="multiple"
                onSelect={setSelectedGroups}
              />
            </div>

            <div className="purpose-modal-inputs">
              <p className="label">
                {intl.formatMessage({ id: 'fleetadmin.purpose.legalbasis' })}
              </p>
              <textarea
                className="comment-area"
                placeholder="Type here ..."
                value={selectedLegalBasis}
                onChange={(e) => setSelectedLegalBasis(e.target.value)}
                draggable={false}
              />
            </div>
          </div>

          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={
              !(
                selectedName &&
                selectedStartDate &&
                selectedMinDurationNumber &&
                selectedMaxDurationNumber &&
                selectedGroups?.length > 0 &&
                selectedCollectionName
              )
            }
          >
            {intl.formatMessage({ id: `submit` })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({ id: `cancel` })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default PurposeStatusModal;
