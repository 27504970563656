import { requestAPI } from '../../../../utils/request';
import {
  adminsRequest,
  adminsSuccess,
  adminsFailure,
  setDisplayAdmins,
  adminsUpdateRequest,
  adminsUpdateFailure,
  adminsCreateFailure,
  userCreateSuccess,
  adminsCreateRequest,
  adminDeleteRequest,
  adminDeleteSuccess,
  adminDeleteFailure,
  adminsUpdateSuccess,
  adminSuccess,
  adminRequest,
  adminFailure,
} from './actions';
import message from '../../../../utils/message';
import { Dispatch } from '../../../../@types';

export const fetchAdmins = () => (dispatch: any, getState: any) => {
  const shouldFetchAdmins = !getState()?.userData.admins?.loading;

  if (shouldFetchAdmins) {
    dispatch(adminsRequest());
    return requestAPI('/fleets/admins')
      .then((data) => {
        dispatch(adminsSuccess(data));
        dispatch(setDisplayAdmins(data));
      })
      .catch((err) => {
        dispatch(adminsFailure(err));
        message.error(err.message);
      });
  } else {
    return Promise.resolve();
  }
};

export const createAdmin = (admin: any) => (dispatch: any) => {
  dispatch(adminsCreateRequest());
  return requestAPI(
    `/fleets/admins`,
    {},
    {
      method: 'POST',
      body: JSON.stringify(admin),
    }
  )
    .then((user) => {
      dispatch(userCreateSuccess(user));
      message.success('Admin created');
    })
    .catch((err) => {
      dispatch(adminsCreateFailure(err));
      message.error(err.message);
    });
};

export const updateAdmin = (admin: any) => (dispatch: any, getState: any) => {
  const { id } = getState()?.userData.admins.selected;

  dispatch(adminsUpdateRequest());
  return requestAPI(
    `/fleets/admins/${id}`,
    {},
    {
      method: 'PUT',
      body: JSON.stringify(admin),
    }
  )
    .then((user) => {
      dispatch(adminsUpdateSuccess(user));
      message.success('Admin updated');
    })
    .catch((err) => {
      dispatch(adminsUpdateFailure(err));
      message.error(err.message);
    });
};

export const deleteAdmin = () => (dispatch: any, getState: any) => {
  const { selected } = getState()?.userData.admins;

  dispatch(adminDeleteRequest());
  return requestAPI(
    `/fleets/admins/${selected.id}`,
    {},
    {
      method: 'DELETE',
    }
  )
    .then((user) => {
      if (user) {
        dispatch(adminDeleteSuccess(user));
        message.success('Admin deleted');
      }
    })
    .catch((err) => {
      dispatch(adminDeleteFailure(err));
      message.error(err.message);
    });
};

export const fetchAdminById =
  (areaId: string | null) => (dispatch: Dispatch) => {
    dispatch(adminRequest());
    requestAPI(`/fleets/admins/${areaId}`)
      .then((area) => {
        if (area.ok === undefined) dispatch(adminSuccess(area));
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(adminFailure(err));
      });
  };
