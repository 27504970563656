import { loginRequest, loginSuccess, loginFailure } from './actions';
import { requestAPI } from '../../../../utils/request';
import { fetchAreasIfNeeded } from '../../appData/areas/index';
import { fetchCarsIfNeeded } from '../cars/index';
import redirectToPath from '../../../../utils/redirectToPath';
import { fetchFleetsIfNeeded } from '../../appData/fleets/index';
import { fetchCategoriesIfNeeded } from '../categories/thunk';
import { fetchCircles } from '../circles';
import { fetchProfiles } from '../profiles';
import { fetchTextsIfNeeded } from '../../appData/texts/thunk';
import { fetchPricingsIfNeeded } from '../../appData/pricings/thunk';

export const login = (token: string) => (dispatch: any) => {
  dispatch(loginRequest());
  requestAPI(`/fleets/admin`)
    .then(async (data) => {
      dispatch(loginSuccess({ ...data, token: token }));
      if (data && data.id) {
        window.localStorage.setItem('userData', JSON.stringify(data));
      }
    })
    .catch((err) => {
      dispatch(loginFailure(err));
    });
};

export const loginByLocalStorage = () => (dispatch: any) => {
  const remember = window.localStorage.getItem('rememberUser');
  const token = window.localStorage.getItem('userToken');

  if (window?.localStorage?.getItem('userData') && token) {
    // @ts-ignore
    try {
      const data = JSON.parse(window.localStorage.getItem('userData'));
      dispatch(loginSuccess({ ...data, token: token }));
      if (remember) {
        // saveCookieToken({ ...data, token });
      }
      setTimeout(() => {
        dispatch(login(token));
        if (data?.permissions?.userGroupsManager) {
          dispatch(fetchCircles());
        }
        dispatch(fetchProfiles());
        dispatch(fetchFleetsIfNeeded());
        dispatch(fetchTextsIfNeeded());
        dispatch(fetchCarsIfNeeded());
        dispatch(fetchAreasIfNeeded());
        dispatch(fetchCategoriesIfNeeded());
        dispatch(fetchPricingsIfNeeded());
      }, 30);
    } catch (e) {
      dispatch(loginFailure(e));
    }
  } else {
    redirectToPath('/login.html');
  }
};
