import css from 'styled-jsx/css';
import { common } from '../../../../theme/theme';

const style = css`
  .policy-status-modal {
    width: 400px;
    padding: 16px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .period-label {
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  .radio-wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .status-selector-wrapper,
  .reason-selector-wrapper,
  .circle-selector-wrapper {
    margin-bottom: 8px;
  }
  .comment-form {
    margin-bottom: 16px;
  }
  .policy-status-modal :global(textarea.text-area) {
    resize: none;
    border: solid 1px #676767;
    border-radius: 4px;
    font-size: 16px;
  }
  .policy-status-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .policy-status-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .clear-checkbox {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .comment-area {
    border: 1px solid #676767;
    border-radius: 4px;
    width: 100%;
    height: 90px;
    resize: none;
  }
`;

export default style;
