import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ViewMode } from '../../hooks/useViewMode';
import styles from './Header.styles';
import { selectViewMode } from '../../redux/store/ui/common';

type HeaderProps = {
  categoryName: string;
  pageName: string;
};

export const Header: React.FC<HeaderProps> = ({ categoryName, pageName }) => {
  return (
    <>
      <div className="wrapper">
        <div className="left">
          <span>
            <span className="category-name">{categoryName}</span>
            <span className="separator" style={{ marginLeft: 4 }}>
              {' '}
              /{' '}
            </span>
            <span className="page-name" style={{ marginLeft: 4 }}>
              {pageName}
            </span>
          </span>
        </div>
        <div className="right" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

const HeaderWrapper = () => {
  const viewMode = useSelector(selectViewMode);
  const intl = useIntl();

  const categoryName = [
    ViewMode.CARE,
    ViewMode.SHARE,
    ViewMode.BOOKING,
  ].includes(viewMode)
    ? intl.formatMessage({ id: 'fleetadmin.header.capability' })
    : [ViewMode.CATEGORIES, ViewMode.PROFILE, ViewMode.PRICING].includes(
        viewMode
      )
    ? intl.formatMessage({ id: 'fleetadmin.header.settings' })
    : [ViewMode.CIRCLE].includes(viewMode)
    ? intl.formatMessage({ id: 'fleetadmin.header.userControl' })
    : intl.formatMessage({ id: 'fleetadmin.header.fleetControl' });

  const pageName = ((vm) => {
    switch (vm) {
      case ViewMode.VEHICLE:
        return intl.formatMessage({ id: 'fleetadmin.header.vehicle' });
      case ViewMode.PROFILE:
        return intl.formatMessage({ id: 'fleetadmin.header.profile' });
      case ViewMode.AREA:
        return intl.formatMessage({ id: 'fleetadmin.header.area' });
      case ViewMode.CARE:
        return intl.formatMessage({ id: 'fleetadmin.header.care' });
      case ViewMode.CLAIM:
        return intl.formatMessage({ id: 'fleetadmin.header.claim' });
      case ViewMode.SHARE:
      case ViewMode.BOOKING:
        return intl.formatMessage({ id: 'fleetadmin.header.share' });
      case ViewMode.CATEGORIES:
        return intl.formatMessage({ id: 'fleetadmin.header.categories' });
      case ViewMode.PRICING:
        return intl.formatMessage({ id: 'fleetadmin.header.pricing' });
      case ViewMode.PRIVACY_POLICY:
        return intl.formatMessage({ id: 'fleetadmin.header.policy' });
      case ViewMode.CIRCLE:
        return intl.formatMessage({ id: 'fleetadmin.header.circle' });
      case ViewMode.USERS:
        return intl.formatMessage({ id: 'fleetadmin.header.users' });
      case ViewMode.RECEIPTS:
        return intl.formatMessage({ id: 'fleetadmin.header.receipts' });
      case ViewMode.ADMINS:
        return intl.formatMessage({ id: 'fleetadmin.header.admins' });
      default:
        return null;
    }
  })(viewMode);

  return <Header categoryName={categoryName} pageName={pageName} />;
};

export default HeaderWrapper;
