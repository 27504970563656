import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse } from '../../../../@types';
import { AdminsTypes } from '../../../../@types/enums/redux/store';

export const adminsRequest = () => createAction(AdminsTypes.ADMINS_REQUEST);

export const adminsSuccess = (payload: any[]) =>
  createAction(AdminsTypes.ADMINS_SUCCESS, payload);

export const adminsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AdminsTypes.ADMINS_FAILURE, error);

export const adminRequest = () => createAction(AdminsTypes.ADMIN_REQUEST);

export const adminSuccess = (payload: any[]) =>
  createAction(AdminsTypes.ADMIN_SUCCESS, payload);

export const adminFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AdminsTypes.ADMIN_FAILURE, error);

export const setDisplayAdmins = (payload: any[]) =>
  createAction(AdminsTypes.SET_DISPLAY_ADMINS, payload);

export const selectAdmin = (payload: any | null) =>
  createAction(AdminsTypes.SELECT_ADMIN, payload);

export const adminsUpdateRequest = () =>
  createAction(AdminsTypes.ADMINS_UPDATE_REQUEST);

export const adminsUpdateSuccess = (payload: any) =>
  createAction(AdminsTypes.ADMINS_UPDATE_SUCCESS, payload);

export const adminsUpdateFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AdminsTypes.ADMINS_UPDATE_FAILURE, error);

export const adminsCreateRequest = () =>
  createAction(AdminsTypes.ADMINS_CREATE_REQUEST);

export const userCreateSuccess = (payload: any) =>
  createAction(AdminsTypes.ADMINS_CREATE_SUCCESS, payload);

export const adminsCreateFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AdminsTypes.ADMINS_CREATE_FAILURE, error);

export const adminDeleteRequest = () =>
  createAction(AdminsTypes.ADMINS_DELETE_REQUEST);

export const adminDeleteSuccess = (payload: any) =>
  createAction(AdminsTypes.ADMINS_DELETE_SUCCESS, payload);

export const adminDeleteFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AdminsTypes.ADMINS_DELETE_FAILURE, error);
