const usersColumns = [
  { label: 'User code', value: 'referralCode', disabled: true }, // Code
  { label: 'Status', value: 'accountStatus', disabled: true }, // Status
  { label: 'Brand', value: 'brandName', disabled: false }, // Marke
  { label: 'User type', value: 'userType', disabled: false }, // Nutzertyp
  { label: 'Signup at', value: 'created', disabled: false }, // Registriert am
  { label: 'User Id', value: 'id', disabled: false }, // ID
  {
    label: 'Merchant reference',
    value: 'merchantUserReference',
    disabled: false,
  }, // operatorID
  { label: 'Title', value: 'title', disabled: false }, // Anrede
  { label: 'First name', value: 'firstName', disabled: false }, // Vorname
  { label: 'Last name', value: 'lastName', disabled: false }, // Nachname
  { label: 'Email', value: 'email', disabled: false }, // E-Mail
  { label: 'Phone number', value: 'phoneNumber', disabled: false }, // Handy-Nr
  { label: 'Driving license', value: 'license.number', disabled: false }, // Führerschein-Nr
  {
    label: 'Tariff',
    value: 'tariff.name',
    disabled: false,
  }, // Tarif
  {
    label: 'Phone verified',
    value: 'verified.phoneNumber',
    disabled: false,
  }, // Status (Handy-Nr.)
  {
    label: 'Identify card',
    value: 'identityDocuments.[0].status',
    disabled: false,
  }, // Status (Ausweis)
  {
    label: 'Driving License',
    value: 'licenses.[0].licenseStatus',
    disabled: false,
  }, // Status (Führerschein)
  {
    label: 'Credit worthiness',
    value: 'creditworthinessData.creditWorthinessStatus',
    disabled: false,
  }, // Status (Bonität)
  {
    label: 'Address exist',
    value: 'verified.address',
    disabled: false,
  }, // Status (Adresse)
  {
    label: 'Payment method',
    value: 'paymentMethods',
    disabled: false,
  }, // hat Zahlungsmittel
  { label: 'Balance', value: 'balance', disabled: false }, // Guthaben
];

export const defaultSelectedUsersColumns = usersColumns.map(
  (userColumn) => userColumn.value
);

export default usersColumns;
