import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './PurposeInfo.styles';
import ListEditItem from '../../shared/ListEditItem';
import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import { renderText } from '../../shared/DrawerLayout/renderText';
import { formatISODuration } from '../../../utils';
import { openConfirmModal, openModal } from '../../../redux/store/ui/common';
import { archivePurpose } from '../../../redux/store/appData/privacyPolicy';

const PurposeInfo = ({ selectedPurpose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onRemove = () => {
    dispatch(
      openConfirmModal({
        destructive: true,
        title: intl.formatMessage({
          id: `fleetadmin.purpose.archive.msg`,
        }),
        onConfirm: () => {
          dispatch(archivePurpose(selectedPurpose?.id));
        },
      })
    );
  };

  const onEdit =
    selectedPurpose?.status === 'PUBLISHED'
      ? undefined
      : () => {
          dispatch(openModal('PurposeStatusModal'));
        };
  const renderStartDate = (value) => (
    <Tag color="#e9d9c4" style={{ color: '#663403' }}>
      {intl.formatMessage({ id: `${value}` })}
    </Tag>
  );

  return (
    <>
      <div className="policy-info">
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.purpose.name`,
          })}
          leftItem={renderText(selectedPurpose.name, true)}
          onClickEdit={onEdit}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.purpose.startdate`,
          })}
          leftItem={renderStartDate(selectedPurpose?.activationEventKey)}
          onClickEdit={onEdit}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.purpose.minduration`,
          })}
          leftItem={
            <span>{formatISODuration(selectedPurpose?.minDuration)}</span>
          }
          onClickEdit={onEdit}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.purpose.maxduration`,
          })}
          leftItem={
            <span>{formatISODuration(selectedPurpose?.maxDuration)}</span>
          }
          onClickEdit={onEdit}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.purpose.groups`,
          })}
          leftItem={
            <div>
              {selectedPurpose?.fieldGroupKeys?.map((group) => (
                <Tag
                  key={group}
                  style={{
                    color: '#666668',
                    backgroundColor: '#e7e7e7',
                    margin: 2,
                  }}
                  className="merchant-tag"
                >
                  {intl.formatMessage({ id: `${group}` })}
                </Tag>
              ))}
            </div>
          }
          onClickEdit={onEdit}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.purpose.legalbasis`,
          })}
          leftItem={renderText(selectedPurpose.legalBasis, true)}
          onClickEdit={onEdit}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.policy.info.name`,
          })}
          leftItem={renderText(selectedPurpose?.policyName, true)}
        />

        {selectedPurpose?.status !== 'PUBLISHED' && (
          <>
            <div className="policy-section-title">
              {intl.formatMessage({
                id: `fleetadmin.users.status.actions`,
              })}
            </div>
            <div className="red-action" onClick={onRemove}>
              {intl.formatMessage({
                id: `fleetadmin.purpose.info.remove`,
              })}
            </div>
          </>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default PurposeInfo;
