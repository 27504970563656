import getFleetNameById from '../../utils/getFleetNameById';
import getNumOfCarsByAreaId from '../../utils/getNumOfCarsByAreaId';
import {
  alphabeticalSorter,
  alphabeticalSorterFor,
  chronologicalSorterFor,
  renderDate,
  getRenderLink,
  getRenderAction,
} from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import { selectAreaAndSwitchToMapView } from '../../redux/store/ui/common';
import { AreaData } from '../../@types';
import fixGeoJSONCoordinates from '../../utils/fixGeoJSONCoordinates';
import getBoundsFromPoints from '../../utils/getBoundsFromPoints';

const getCenterFromGeoJson = (geoJsonData: any) => {
  const geoJson = fixGeoJSONCoordinates(geoJsonData);
  const bounds = getBoundsFromPoints(
    // @ts-ignore
    geoJson.features[0].geometry.coordinates[0]
  );
  const center = {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  };

  return `${center.lat?.toString()?.slice(0, 10)},${center.lng
    ?.toString()
    ?.slice(0, 10)}`;
};

const getColumns = ({ cars, fleets, dispatch }: any) => {
  const renderFleet = (fleetId: any) => (
    <span>{getFleetNameById(fleets, fleetId)}</span>
  );

  const renderNumOfCars = (areaId: any) => {
    return <span>{getNumOfCarsByAreaId(cars, areaId)}</span>;
  };

  const renderAction = getRenderAction((area: AreaData) =>
    dispatch(selectAreaAndSwitchToMapView(area))
  );

  const renderCenter = (_, record) => (
    <span>{getCenterFromGeoJson(record?.geoJSONData)}</span>
  );

  return [
    {
      title: 'Area',
      dataIndex: 'name',
      ellipsis: true,
      width: isDesktop() ? 200 : 150,
      fixed: 'left' as 'left',
      render: getRenderLink(),
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: 'Fleet',
      dataIndex: 'fleetId',
      render: renderFleet,
      ellipsis: true,
      width: 150,
      sorter: (areaA: { fleetId: any }, areaB: { fleetId: any }) => {
        const fleetNameA = getFleetNameById(fleets, areaA.fleetId);
        const fleetNameB = getFleetNameById(fleets, areaB.fleetId);
        return alphabeticalSorter(fleetNameA, fleetNameB);
      },
    },
    {
      title: 'No.Vehicles',
      dataIndex: 'id',
      render: renderNumOfCars,
      width: 150,
      sorter: (areaA: { id: any }, areaB: { id: any }) => {
        const A = getNumOfCarsByAreaId(cars, areaA.id);
        const B = getNumOfCarsByAreaId(cars, areaB.id);
        return B - A;
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      width: 150,
      render: renderDate,
      sorter: chronologicalSorterFor('createdAt'),
    },
    {
      title: 'Last modified',
      dataIndex: 'lastUpdatedAt',
      width: 150,
      render: renderDate,
      sorter: chronologicalSorterFor('lastUpdatedAt'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 220,
      render: renderCenter,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: 170,
      render: renderAction,
    },
  ];
};

export default getColumns;
