import React, { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useDispatch } from 'react-redux';
import LoadingIcon from '../../shared/LoadingIcon';
import {
  fetchUserById,
  uploadUserDocument,
} from '../../../redux/store/userData/users';

const UserFileUpload = ({ selectedUser }) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  // @ts-ignore
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    readAs: 'DataURL',
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
  });
  const isLoading = loading || uploading;

  useEffect(() => {
    console.log('filesContent?.[0]?.content', filesContent?.[0]?.content);
    if (filesContent?.[0]?.content) {
      setUploading(true);
      dispatch(
        uploadUserDocument(
          selectedUser?.id,
          filesContent?.[0]?.content,
          'OTHER',
          () => {
            setUploading(false);
            dispatch(fetchUserById(selectedUser?.id!));
          }
        )
      );
    }
  }, [filesContent]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 24 }}
    >
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <img
          style={{ width: 18, height: 18, cursor: 'pointer' }}
          src={'/images/drawer/add.svg'}
          alt="edit"
          onClick={openFileSelector}
        />
      )}
    </div>
  );
};

UserFileUpload.defaultProps = {};

export default UserFileUpload;
