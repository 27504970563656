import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import { Table } from 'antd';
import {
  selectDisplayColumnsForPrivacyPolicy,
  selectPrivacyPolicyWithUIEffect,
  selectPurposeWithUIEffect,
} from '../../redux/store/ui/common';
import {
  fetchPrivacyPoliciesIfNeeded,
  selectDisplayPrivacyPolicy,
  selectPrivacyPolicies,
  selectSelectedPrivacyPolicy,
  selectSelectedPurpose,
} from '../../redux/store/appData/privacyPolicy';
import { PrivacyPolicyData, ProcessingPurposeData } from '../../@types';
import getPurposesColumns from './getPurposesColumns';
import { useIntl } from 'react-intl';
import { getFilteredColumns } from '../../utils';

const PrivacyPoliciesTable = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const displayPrivacyPolicies: any[] = useSelector(selectDisplayPrivacyPolicy);
  const privacyPoliciesEntities = useSelector(selectPrivacyPolicies);
  const selectedPrivacyPolicy: any = useSelector(selectSelectedPrivacyPolicy);
  const selectedPurpose: any = useSelector(selectSelectedPurpose);
  const displayColumns = useSelector(selectDisplayColumnsForPrivacyPolicy);
  const selectedId = selectedPrivacyPolicy ? selectedPrivacyPolicy.id : null;
  const columns = getColumns(intl);
  const purposesColumns = getPurposesColumns(intl);
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: PrivacyPolicyData) => {
    dispatch(selectPrivacyPolicyWithUIEffect(record));
  };

  const onClickPurposeRow = (record: ProcessingPurposeData) => {
    dispatch(selectPurposeWithUIEffect(record));
  };

  const loadPrivacyPolicies = useCallback(async () => {
    dispatch(fetchPrivacyPoliciesIfNeeded());
  }, []);

  useEffect(() => {
    if (!privacyPoliciesEntities) {
      loadPrivacyPolicies();
    }
  }, []);

  return (
    <TableLayout
      rowKey="pricing"
      columns={filteredColumns}
      dataSource={displayPrivacyPolicies}
      selectedId={selectedId}
      totalNumber={displayPrivacyPolicies?.length}
      onClickRow={onClickRow}
      expandedRowRender={(record) => (
        <Table
          columns={purposesColumns}
          dataSource={[...record.processingPurposes]}
          pagination={false}
          rowClassName={(record) =>
            record?.id === selectedPurpose?.id ? 'selected-row' : ''
          }
          onRow={(record) => ({
            onClick: (e) => {
              e.preventDefault();
              onClickPurposeRow(record);
            },
          })}
        />
      )}
    />
  );
};

export default PrivacyPoliciesTable;
