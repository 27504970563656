import { Reducer } from '../../../../@types';
import { UsersTypes } from '../../../../@types/enums';
import { initialState, UsersState } from './initialState';

// @ts-ignore
export const usersReducer: Reducer<UsersState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case UsersTypes.USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UsersTypes.UPLOAD_DL_REQUEST:
      return {
        ...state,
        uploadingImage: true,
      };
    case UsersTypes.USERS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case UsersTypes.USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case UsersTypes.SELECT_USER:
      return {
        ...state,
        selected: action.payload,
        selectedUserTransactions: null,
        userTransactionsLoading: true,
      };
    case UsersTypes.SET_DISPLAY_USERS:
      return {
        ...state,
        display: action.payload,
      };
    case UsersTypes.SET_TOTAL_USERS:
      return {
        ...state,
        total: action.payload,
      };
    case UsersTypes.USERS_UPDATE_REQUEST:
    case UsersTypes.USER_DUPLICATES_REQUEST:
      return {
        ...state,
      };
    case UsersTypes.USERS_UPDATE_SUCCESS: {
      return {
        ...state,
        entities: state?.entities
          ? [
              // @ts-ignore
              ...state.entities.map((entity) =>
                entity.id === action.payload.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        display: state.display
          ? [
              // @ts-ignore
              ...state.display.map((entity) =>
                entity.id === action.payload.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        selected: { ...action.payload },
      };
    }
    case UsersTypes.USER_DUPLICATES_SUCCESS: {
      return {
        ...state,
        selected:
          state?.selected?.id === action?.payload?.id
            ? { ...state.selected, userDuplicates: action.payload.duplicates }
            : state.selected,
      };
    }
    case UsersTypes.USER_DUPLICATE_SUCCESS: {
      return {
        ...state,
        selectedDuplicate: action?.payload,
        loading: false,
        uploadingImage: false,
      };
    }
    case UsersTypes.USER_TRANSACTION_REQUEST:
      return {
        ...state,
        userTransactionsLoading: true,
      };
    case UsersTypes.USER_TRANSACTION_SUCCESS: {
      return {
        ...state,
        userTransactionsLoading: false,
        selectedUserTransactions:
          state?.selectedUserTransactions &&
          // Merge transactions if it's a second page or if it's all wallet type where mergeTransactions flag is true
          // @ts-ignore
          state?.selectedUserTransactions?.userId === state?.selected?.id &&
          (action.payload.transactions.offset !== 0 ||
            action.payload?.transactions?.mergeTransactions)
            ? {
                ...action.payload.transactions,
                // If merging transaction of two wallets, total count is the highest of the two
                totalCount: action.payload?.transactions?.mergeTransactions
                  ? action.payload?.transactions?.totalCount >
                    state?.selectedUserTransactions?.totalCount
                    ? action.payload.transactions.totalCount
                    : state.selectedUserTransactions.totalCount
                  : action.payload?.transactions?.totalCount,
                // When merging sort based on date, so two wallets transactions are ordered correctly
                transactions: [
                  ...(state?.selectedUserTransactions?.transactions || []),
                  ...action.payload.transactions.transactions,
                ]?.sort(
                  (a, b) =>
                    new Date(b?.date)?.getTime() - new Date(a?.date)?.getTime()
                ),
              }
            : {
                ...action.payload.transactions,
                transactions: [
                  ...action.payload.transactions.transactions,
                ]?.sort(
                  (a, b) =>
                    new Date(b?.date)?.getTime() - new Date(a?.date)?.getTime()
                ),
              },
      };
    }
    case UsersTypes.SYNC_REQUEST:
      return {
        ...state,
      };
    case UsersTypes.SYNC_SUCCESS: {
      return {
        ...state,
        entities: state?.entities
          ? [
              // @ts-ignore
              ...state.entities.map((entity) =>
                entity.id === action.payload.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        display: state.display
          ? [
              // @ts-ignore
              ...state.display.map((entity) =>
                entity.id === action.payload.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        selected: { ...action.payload },
        loading: false,
        uploadingImage: false,
      };
    }
    case UsersTypes.CANCEL_DEPOSIT_SUCCESS: {
      return {
        ...state,
        entities: state?.entities
          ? [
              // @ts-ignore
              ...state.entities.map((entity) =>
                entity.id === action.payload.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        display: state.display
          ? [
              // @ts-ignore
              ...state.display.map((entity) =>
                entity.id === action.payload.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        selected: { ...action.payload },
        loading: false,
        uploadingImage: false,
      };
    }
    case UsersTypes.USERS_ARCHIVE_SUCCESS: {
      return {
        ...state,
        entities: state?.entities
          ? [
              // @ts-ignore
              ...state.entities.filter(
                (entity) => entity.id !== action.payload.id
              ),
            ]
          : null,
        display: state.display
          ? [
              // @ts-ignore
              ...state.display.filter(
                (entity) => entity.id !== action.payload.id
              ),
            ]
          : null,
        selected: null,
        loading: false,
        uploadingImage: false,
      };
    }
    case UsersTypes.TARIFFS_SUCCESS: {
      return {
        ...state,
        tariffs: action.payload,
      };
    }
    case UsersTypes.PAYMENT_DELETE_SUCCESS: {
      return {
        ...state,
        entities: [
          // @ts-ignore
          ...state.entities.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        display: [
          // @ts-ignore
          ...state.display.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        selected: { ...state.selected, ...action.payload },
      };
    }
    case UsersTypes.SET_USER_PAYMENT_ID:
      return {
        ...state,
        loading: false,
        userPaymentId: action.payload,
      };
    case UsersTypes.USERS_PDF_REQUEST:
      return {
        ...state,
        certificateLoading: true,
      };
    case UsersTypes.USERS_PDF_SUCCESS:
    case UsersTypes.USERS_PDF_FAILURE:
      return {
        ...state,
        certificateLoading: false,
      };
    case UsersTypes.DOWNLOAD_GDPR_REQUEST:
      return {
        ...state,
        loadingGDPR: true,
      };
    case UsersTypes.DOWNLOAD_GDPR_SUCCESS:
    case UsersTypes.DOWNLOAD_GDPR_FAILURE:
      return {
        ...state,
        loadingGDPR: false,
      };
    case UsersTypes.USERS_UPDATE_FAILURE:
    case UsersTypes.USER_DUPLICATES_FAILURE:
    case UsersTypes.USER_TRANSACTION_FAILURE:
    case UsersTypes.SYNC_FAILURE:
    case UsersTypes.PAYMENT_DELETE_FAILURE:
    case UsersTypes.CANCEL_DEPOSIT_FAILURE:
    case UsersTypes.TARIFFS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        userTransactionsLoading: false,
      };
    default:
      return state;
  }
};
