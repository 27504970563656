import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, AreaData } from '../../../../@types';
import { AreasTypes } from '../../../../@types/enums';

export const areasRequest = () => createAction(AreasTypes.AREAS_REQUEST);

export const areasSuccess = (payload: AreaData[]) =>
  createAction(AreasTypes.AREAS_SUCCESS, payload);

export const areasFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AreasTypes.AREAS_FAILURE, error);

export const areaByIDRequest = () =>
  createAction(AreasTypes.AREA_BY_ID_REQUEST);

export const areaByIDSuccess = (payload: AreaData[]) =>
  createAction(AreasTypes.AREA_BY_ID_SUCCESS, payload);

export const areaByIDFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AreasTypes.AREA_BY_ID_FAILURE, error);

export const selectArea = (payload: AreaData | null) =>
  createAction(AreasTypes.SELECT_AREA, payload);

export const setDisplayAreas = (payload: AreaData[]) =>
  createAction(AreasTypes.SET_DISPLAY_AREAS, payload);

export const disableAreaRequest = () =>
  createAction(AreasTypes.DISABLE_AREA_REQUEST);

export const disableAreaSuccess = (payload: AreaData) =>
  createAction(AreasTypes.DISABLE_AREA_SUCCESS, payload);

export const disableAreaFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AreasTypes.DISABLE_AREA_FAILURE, error);

export const uploadAreaFileRequest = () =>
  createAction(AreasTypes.UPLOAD_AREA_FILE_REQUEST);

export const uploadAreaFileSuccess = (payload: AreaData) =>
  createAction(AreasTypes.UPLOAD_AREA_FILE_SUCCESS, payload);

export const uploadAreaFileFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AreasTypes.UPLOAD_AREA_FILE_FAILURE, error);

export const createAreaRequest = () =>
  createAction(AreasTypes.CREATE_AREA_REQUEST);

export const createAreaSuccess = (payload: AreaData) =>
  createAction(AreasTypes.CREATE_AREA_SUCCESS, payload);

export const createAreaFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AreasTypes.CREATE_AREA_FAILURE, error);

type AreaId = string;

export const addHideOnMap = (payload: AreaId) =>
  createAction(AreasTypes.ADD_HIDE_ON_MAP, payload);

export const removeHideOnMap = (payload: AreaId) =>
  createAction(AreasTypes.REMOVE_HIDE_ON_MAP, payload);

export const archiveAreaRequest = () =>
  createAction(AreasTypes.ARCHIVE_AREA_REQUEST);

export const archiveAreaSuccess = (payload: AreaData) =>
  createAction(AreasTypes.ARCHIVE_AREA_SUCCESS, payload);

export const archiveAreaFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(AreasTypes.ARCHIVE_AREA_FAILURE, error);
