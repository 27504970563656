import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styles from './LinkToTrip.styles';
import { selectCare } from '../../redux/store/userData/cares';
import { fetchTripById } from '../../redux/store/userData/trips';
import { fetchVehicleEventsForTrip } from '../../redux/store/userData/vehicleEvents';
import { selectAuth } from '../../redux/store/userData/user';
import {
  selectSelectedTrip,
  selectTripLoading,
} from '../../redux/store/userData/trips';
import { selectSelectedDrawersCount } from '../../redux/store/ui/common';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingIcon from './LoadingIcon';

interface Props {
  tripCode: string;
  tripId?: string;
  service: any;
}

const LinkToTrip: React.FC<Props> = ({ tripCode, tripId, service }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const selectedTrip = useSelector(selectSelectedTrip);
  const isTripManager = user?.permissions?.tripsManager;
  const isTripLoading = useSelector(selectTripLoading);
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);

  const onClickHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (
      isTripManager &&
      !isTripLoading &&
      !selectedTrip &&
      openedDrawersCount < 2
    ) {
      if (service) {
        const { id, reservedAt, tripEnd, carId } = service;
        batch(() => {
          dispatch(selectCare(service));
          dispatch(
            fetchVehicleEventsForTrip(
              carId!,
              id!,
              reservedAt as any,
              tripEnd as any,
              true
            )
          );
        });
      } else {
        dispatch(fetchTripById(tripId));
      }
    }
  };

  return (
    <>
      {openedDrawersCount < 2 ? (
        <span
          className={isTripManager ? 'link' : 'value-wrapper'}
          onClick={onClickHandler}
        >
          {tripCode || '-'}
          {isTripLoading && <LoadingIcon />}
        </span>
      ) : (
        <Link
          replace={false}
          to={{
            pathname: '/dashboard',
            search: `?vm=SHARE&type=TABLE&pageSize=50&pageNumber=1&code=${
              tripCode || selectedTrip?.id
            }`,
          }}
          target="_blank"
        >
          <a className="external-link-wrapper" target="_blank" rel="noreferrer">
            <span className="link"> {tripCode || '-'}</span>
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginLeft: '10px' }}
            />
          </a>
        </Link>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToTrip;
