import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getFleetNameById from '../../utils/getFleetNameById';
import fixGeoJSONCoordinates from '../../utils/fixGeoJSONCoordinates';
import getBoundsFromPoints from '../../utils/getBoundsFromPoints';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import UploadFileButton from './UploadFileButton';
import styles from './AreaInfo.styles';
import { selectSelectedArea } from '../../redux/store/appData/areas';
import { selectFleets } from '../../redux/store/appData/fleets';
import ListItem from '../../components/shared/ListItem';
import { selectAuth } from '../../redux/store/userData/user/selectors';
import { openModal } from '../../redux/store/ui/common';

const getCenterFromGeoJson = (geoJsonData: any) => {
  const geoJson = fixGeoJSONCoordinates(geoJsonData);
  const bounds = getBoundsFromPoints(
    // @ts-ignore
    geoJson.features[0].geometry.coordinates[0]
  );
  const center = {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  };

  return `${center.lat?.toString()?.slice(0, 10)},${center.lng
    ?.toString()
    ?.slice(0, 10)}`;
};

const AreaInfo: React.FC = () => {
  const dispatch = useDispatch();
  const selectedArea = useSelector(selectSelectedArea);
  const fleets = useSelector(selectFleets);
  const user = useSelector(selectAuth);

  const onArchiveArea = () => {
    dispatch(openModal('ArchiveAreaModal'));
  };

  return (
    <>
      <div className="area-info">
        <ListItem
          label="Fleet"
          value={getFleetNameById(fleets, selectedArea?.fleetId)}
        />
        <ListItem
          label="Location"
          value={
            <LinkToGoogleMap
              position={getCenterFromGeoJson(selectedArea?.geoJSONData)}
            />
          }
        />
        <ListItem
          label="File"
          value={
            <a href={selectedArea?.kmlFileUrl}>{selectedArea?.kmlFilename}</a>
          }
        />
        <ListItem
          label="Updated By"
          value={getFleetNameById(fleets, selectedArea?.lastUpdatedBy)}
        />
        {user.permissions?.fleetManager && (
          <div className="button-wrapper">
            <UploadFileButton />
            <div className="archive-area-button" onClick={onArchiveArea}>
              Archive area
            </div>
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AreaInfo;
