import { Reducer } from '../../../../@types';
import { ProfilesTypes } from '../../../../@types/enums';
import { initialState, State } from './initialState';

// @ts-ignore
export const profilesReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case ProfilesTypes.PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ProfilesTypes.PROFILES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case ProfilesTypes.PROFILES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ProfilesTypes.PROFILE_REQUEST:
      return {
        ...state,
        loadingProfile: true,
      };
    case ProfilesTypes.PROFILE_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities!.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state?.display
          ? [
              ...state.display!.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
        loadingProfile: false,
      };
    case ProfilesTypes.PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingProfile: false,
      };
    case ProfilesTypes.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updatingProfile: true,
      };
    case ProfilesTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        updatingProfile: false,
      };
    case ProfilesTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        updatingProfile: false,
      };
    case ProfilesTypes.CREATE_PROFILE_REQUEST:
      return {
        ...state,
        creatingProfile: true,
      };
    case ProfilesTypes.CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        selected: action.payload,
        creatingProfile: false,
      };
    case ProfilesTypes.CREATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        creatingProfile: false,
      };
    case ProfilesTypes.SELECT_PROFILE:
      return {
        ...state,
        selected: action.payload,
      };
    case ProfilesTypes.SET_DISPLAY_PROFILES:
      return {
        ...state,
        display: action.payload,
      };
    case ProfilesTypes.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadingImage: true,
      };
    case ProfilesTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        uploadingImage: false,
      };
    case ProfilesTypes.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        uploadingImage: false,
      };
    case ProfilesTypes.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        deletingImage: true,
      };
    case ProfilesTypes.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        deletingImage: false,
      };
    case ProfilesTypes.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingImage: false,
      };
    default:
      return state;
  }
};
