/* eslint-disable no-undef */
import { Dispatch, GetState } from '../@types';
import { localize } from '../redux/helpers/localize';
import message from './message';

const DEFAULT_TIMEOUT = 90 * 1000;
const API_VERSION = '1.1.20';
const BASE_API_URL = process.env.REACT_APP_API_URL;
export class ViolationError extends Error {
  violations: Object;
  constructor(message, violations) {
    super(message);
    this.name = 'ViolationError';
    this.violations = violations;
  }
}

const defaultOptions: RequestInit = {
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'X-API-Version': API_VERSION,
    'Content-Type': 'application/json',
  },
};

export const STATUS_MESSAGES: Record<number, string> = {
  401: 'UNAUTHORIZED USER',
  403: 'ACTION IS NOT ALLOWED',
  404: 'NOT_FOUND',
  415: 'UNSUPPORTED_MEDIA_FILE',
  500: 'INTERNAL_SERVER_ERROR',
  501: 'BAD_GATEWAY',
};

const trimStringProperties = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
    return acc;
  }, {});
};

const getHeaders = async (): Promise<Record<string, string>> => {
  const user = await JSON.parse(
    window.localStorage.getItem('userData') || '{}'
  );
  const token = window.localStorage.getItem('userToken');
  return {
    Authorization: token,
    'Accept-Language': user?.locale,
  };
};

const parseJsonAndHandleErrors: (arg: any) => any = async (res: Response) => {
  try {
    const contentType = res.headers.get('Content-Type') || '';
    if (contentType.includes('application/json')) {
      const resStr = await res.text();
      const data = resStr ? JSON.parse(resStr) : {};
      if (res.ok) {
        return data;
      }
      if (data.violations != null) {
        const firstViolation = Object.values(data.violations)[0];
        throw new ViolationError(String(firstViolation), data.violations);
      }
    } else {
      return res;
    }
  } catch (err: any) {
    if (err instanceof ViolationError) {
      throw err;
    }
    throw new Error(
      res.status === 400
        ? err?.toString()?.split(':')[1]?.trim() || err
        : STATUS_MESSAGES[res.status] || 'UNHANDLED_ERROR'
    );
  }
};

export const fetchWithTimeout = (
  url: string,
  options: RequestInit,
  timeout: number = DEFAULT_TIMEOUT
): Promise<Response> => {
  const controller = new AbortController();
  const { signal } = controller;

  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'));
      controller.abort();
    }, timeout);

    const mergedOptions: RequestInit = {
      ...options,
      signal,
    };

    fetch(url, mergedOptions)
      .catch(() => {
        throw new Error('NETWORK_ERROR');
      })
      .finally(() => clearTimeout(timer))
      .then(resolve, reject);
  });
};

export const request = (url: any, options: RequestInit) =>
  fetchWithTimeout(url, options).then(parseJsonAndHandleErrors);

export const requestAPI = async (
  endpoint: string,
  params: Record<string, string | string[]> = {},
  options: RequestInit = defaultOptions
) => {
  const url = new URL(`${BASE_API_URL}${endpoint}`);

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => url.searchParams.append(key, v));
    } else {
      url.searchParams.append(key, value);
    }
  });

  options = { ...defaultOptions, ...options };

  const headers = await getHeaders();
  options.headers = { ...options.headers, ...headers };
  if (options.body instanceof FormData && options.body.has('file')) {
    options.headers = {
      Authorization: headers['Authorization'],
    };
  }

  if (options.headers['Content-Type'] === 'application/json' && options.body) {
    if (typeof options.body === 'string') {
      options.body = JSON.stringify(
        trimStringProperties(JSON.parse(options.body))
      );
    }
  }

  const response = await request(url.toString(), options);
  return response;
};

export const updateAPI = (
  requestAction: any,
  successAction: any,
  failureAction: any,
  urlPath: string
) => {
  return (itemId: string, item: any, successCallback = () => {}) =>
    (dispatch: Dispatch, getState: GetState) => {
      dispatch(requestAction());
      return requestAPI(
        `${urlPath}${itemId}`,
        {},
        { method: 'PUT', body: JSON.stringify(item) }
      )
        .then((data: any) => {
          if (data && data?.ok === undefined) {
            dispatch(successAction(data));
            successCallback();
            message.success(localize('update.success', getState));
          } else {
            message.error('Error');
          }
        })
        .catch((err: any) => {
          dispatch(failureAction(err));
          message.error(err.message);
        });
    };
};
