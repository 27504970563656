import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import styles from './EbusOptionsModal.styles';
import {
  getIsVisibleModal,
  closeModal,
} from '../../../../redux/store/ui/common';
import DrawerModal from '../../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../../redux/store/userData/users';
import { FRM_PRODUCT_OPTIONS } from './FRM_PRODUCT_OPTIONS';
import SelectorLayout from '../../../shared/SelectorLayout';

interface UserInput {
  forcedProductId?: string;
  forcedProductOptions?: string | string[];
}

const EbusOptionsModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser) as any;
  const selectedProductID = selectedUser?.tariff?.ebusId;
  const userProductOptions =
    selectedUser?.forcedProductOptions ||
    selectedUser?.ebusProductOptions ||
    [];

  const product = FRM_PRODUCT_OPTIONS?.find(
    (p) => p.productID === selectedProductID
  );

  const [forcedProductId, setTestingProductId] = useState(
    product?.productID || selectedUser?.forcedProductId || ''
  );
  const [forcedProductOptions, setTestingProductOptions] =
    useState(userProductOptions);

  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});

  const visible = useSelector(getIsVisibleModal('EbusOptionsModal'));

  const handleCancel = () => {
    dispatch(closeModal('EbusOptionsModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    let userInput: UserInput = {};

    if (Object.keys(selectedOptions).length > 0) {
      userInput.forcedProductId = forcedProductId;
      userInput.forcedProductOptions = Object.values(selectedOptions).join(',');
    } else {
      if (forcedProductId !== selectedUser?.forcedProductId) {
        userInput.forcedProductId = forcedProductId;
      }
      if (forcedProductOptions !== selectedUser.forcedProductOptions) {
        userInput.forcedProductOptions = forcedProductOptions;
      }
    }

    if (Object.keys(userInput).length > 0) {
      dispatch(updateUser(selectedUser?.id, userInput));
      handleCancel();
    }
  };

  const handleOptionChange = (optionName: string, value: string) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: value,
    }));
  };

  useEffect(() => {
    if (visible) {
      setTestingProductId(
        product?.productID || selectedUser?.forcedProductId || ''
      );
      setTestingProductOptions(userProductOptions);

      // Prefill selected options with values from ebusProductOptions or user tariff
      const initialSelectedOptions: { [key: string]: string } = {};
      if (product) {
        product.productOptions.forEach((option) => {
          const prefillValue = option.options.find(
            (opt) =>
              userProductOptions.includes(opt.value) ||
              selectedUser?.tariff?.ebusProductOptions.includes(opt.value)
          );
          if (prefillValue) {
            initialSelectedOptions[option.name] = prefillValue.value;
          }
        });
      }
      setSelectedOptions(initialSelectedOptions);
    }
  }, [visible, selectedProductID, userProductOptions]);

  const renderProductOptions = () => {
    if (!product) return null;

    return product.productOptions.map((option) => {
      if (option.type === 'selection') {
        return (
          <div
            key={option.name}
            className="product-option"
            style={{ marginBottom: 8 }}
          >
            <div className="label">
              {intl.formatMessage({ id: option.labelKey })}
            </div>
            <SelectorLayout
              keyPrefix="area-select"
              value={selectedOptions[option.name] || ''}
              placeholder={intl.formatMessage({ id: option.labelKey })}
              onSelect={(value) => handleOptionChange(option.name, value)}
              options={option.options.map((opt) => ({
                label: opt.label,
                value: opt.value,
              }))}
            />
          </div>
        );
      }
    });
  };

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="ebus-options-contents">
          <div className="ebus-options-title">
            {intl.formatMessage({ id: `fleetadmin.users.info.ebus` })}
          </div>
          <div className="ebus-options-input-container">
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.product`,
              })}
            </div>
            <Input
              value={forcedProductId}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.ebus.product`,
              })}
              onChange={(e) => setTestingProductId(e.target.value)}
              style={{ marginBottom: 12 }}
            />
            {!product ? (
              <>
                <div className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.ebus.options`,
                  })}
                </div>
                <Input
                  value={forcedProductOptions}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.options`,
                  })}
                  onChange={(e) => setTestingProductOptions(e.target.value)}
                />
              </>
            ) : (
              renderProductOptions()
            )}
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default EbusOptionsModal;
