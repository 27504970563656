import { Reducer } from '../../../../@types';
import { AdminsTypes } from '../../../../@types/enums';
import { initialState, UsersState } from './initialState';

// @ts-ignore
export const adminsReducer: Reducer<UsersState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case AdminsTypes.ADMINS_REQUEST:
    case AdminsTypes.ADMINS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AdminsTypes.ADMINS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AdminsTypes.ADMINS_SUCCESS:
      return {
        ...state,
        entities: action.payload.map((admin) => ({
          ...admin,
          ...admin?.permissions,
        })),
        loading: false,
      };
    case AdminsTypes.ADMINS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case AdminsTypes.ADMIN_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state?.display
          ? [
              ...state.display.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
      };
    case AdminsTypes.SELECT_ADMIN:
      return {
        ...state,
        selected: action.payload,
      };
    case AdminsTypes.SET_DISPLAY_ADMINS:
      return {
        ...state,
        display: action.payload,
      };
    case AdminsTypes.ADMINS_UPDATE_SUCCESS: {
      return {
        ...state,
        entities: [
          // @ts-ignore
          ...state.entities.map((entity) =>
            entity.id === action.payload.id
              ? {
                  ...entity,
                  ...action.payload,
                }
              : entity
          ),
        ],
        display: [
          // @ts-ignore
          ...state.display.map((entity) =>
            entity.id === action.payload.id
              ? {
                  ...entity,
                  ...action.payload,
                }
              : entity
          ),
        ],
        selected: {
          ...action.payload,
        },
        loading: false,
      };
    }
    case AdminsTypes.ADMINS_DELETE_SUCCESS:
      return {
        ...state,
        entities: state?.entities?.filter(
          (area) => area.id !== action.payload.id
        ),
        display: state?.display?.filter(
          (area) => area.id !== action.payload.id
        ),
        selected: null,
        loading: false,
      };
    case AdminsTypes.ADMINS_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AdminsTypes.ADMINS_CREATE_SUCCESS: {
      return {
        ...state,
        entities: [
          // @ts-ignore
          ...state.entities,
          { ...action.payload, ...action.payload?.permissions },
        ],
        display: [
          // @ts-ignore
          ...state.display,
          { ...action.payload, ...action.payload?.permissions },
        ],
        selected: {
          ...action.payload,
          ...action.payload?.permissions,
        },
        loading: false,
      };
    }
    case AdminsTypes.ADMINS_DELETE_FAILURE:
    case AdminsTypes.ADMINS_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case AdminsTypes.ADMINS_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
