import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableLayout from '../../components/shared/TableLayout';
import getColumns from './getColumns';
import { FleetTripData } from '../../@types';
// @ts-ignore
import cookie from 'js-cookie';
import { selectAreas } from '../../redux/store/appData/areas';
import {
  selectDisplayCares,
  selectSelectedCare,
  selectCares,
  fetchCaresIfNeeded,
} from '../../redux/store/userData/cares';
import {
  selectCareWithUIEffect,
  selectDisplayColumnsForCare,
} from '../../redux/store/ui/common';
import { selectFleets } from '../../redux/store/appData/fleets';
import { useQuery } from '../../hooks/useQuery';
import { getFilteredColumns } from '../../utils';

const CareTable = () => {
  const queries = useQuery();
  const dispatch = useDispatch();
  const areas = useSelector(selectAreas);
  const fleets = useSelector(selectFleets);
  const displayCares = useSelector(selectDisplayCares);
  const selectedCare: FleetTripData | null = useSelector(selectSelectedCare);
  const careEntities = useSelector(selectCares);
  const displayColumns = useSelector(selectDisplayColumnsForCare);
  const selectedId = (selectedCare != null && selectedCare.id) || null;
  const columns = getColumns({ fleets, areas });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: FleetTripData | null) => {
    dispatch(selectCareWithUIEffect(record));
  };

  const loadCares = useCallback(async () => {
    const cookieToken = await cookie.get('token');
    dispatch(fetchCaresIfNeeded(cookieToken!));
  }, []);

  useEffect(() => {
    if (!careEntities) {
      loadCares();
    }
  }, []);

  return (
    <TableLayout
      rowKey="care"
      columns={filteredColumns}
      dataSource={displayCares}
      selectedId={selectedId}
      totalNumber={displayCares?.length}
      onClickRow={onClickRow}
    />
  );
};

export default CareTable;
