import { batch } from 'react-redux';
import { ViewType } from '../../../../hooks/useViewType';
import { setQueries } from '../../../../libs/setQueries';
import { fetchAreaById, selectArea } from '../../appData/areas';
import {
  fetchCarByIdIfNeeded,
  selectCar,
  fetchCarActiveBookings,
  setCarDrawerActiveTab,
} from '../../userData/cars';
import { fetchCareById, selectCare } from '../../userData/cares';
import {
  selectClaim,
  fetchClaimsByCarId,
  fetchClaimById,
} from '../../userData/claims';
import {
  fetchBookingById,
  fetchTripById,
  selectBooking,
  selectTrip,
} from '../../userData/trips';
import {
  fetchVehicleEvents,
  fetchVehicleEventsForCar,
} from '../../userData/vehicleEvents';
import { fetchCarConditionsIfNeeded } from '../../../helpers';
import { fetchCategoryById, selectCategory } from '../../userData/categories';
import getTheDayBefore from '../../../../utils/datetime/getTheDayBefore';
import getOneHourAfter from '../../../../utils/datetime/getOneHourAfter';
import { fetchCircleById, selectCircle } from '../../userData/circles';
import { fetchPricingById, selectPricing } from '../../appData/pricings';
import { paramsToObject } from '../../../../hooks/useQuery';
import { fetchProfileById, selectProfile } from '../../userData/profiles';
import {
  Dispatch,
  FleetAdminCarData,
  GetState,
  FleetAdminCircleData,
  AreaData,
  VehicleCategoryData,
  PricingTableData,
  VehicleProfileInfoData,
  PrivacyPolicyData,
  ProcessingPurposeData,
  RentalData,
} from '../../../../@types';
import {
  fetchUserById,
  fetchUserDuplicates,
  fetchUserTransactions,
  selectUser,
  userDuplicateSuccess,
} from '../../userData/users';
import { fetchAdminById, selectAdmin } from '../../userData/admins';
import { selectReceipt } from '../../userData/receipts';
import { fetchReceiptById } from '../../userData/receipts/thunk';
import {
  fetchPrivacyPolicyById,
  fetchPurposeById,
  selectPrivacyPolicy,
  selectPurpose,
} from '../../appData/privacyPolicy';

function hasOnlyIdProperty(obj) {
  const keys = Object.keys(obj);
  return keys?.length === 1 && keys?.[0] === 'id';
}

export const selectCarWithoutMapEffect = (
  car: FleetAdminCarData,
  shouldUpdateQuery: boolean = true,
  activeTab: number = 0
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().userData.user;
    const selectedClaim = getState().userData.claims.selected;

    batch(() => {
      dispatch(setCarDrawerActiveTab(activeTab));
      if (!hasOnlyIdProperty(car)) dispatch(selectCar(car));
      dispatch(selectArea(null));
      dispatch(fetchCarByIdIfNeeded(token, car.id!));
      setTimeout(() => {
        dispatch(fetchCarConditionsIfNeeded(token, car.id!, false));
        dispatch(fetchVehicleEventsForCar(car.id));
        dispatch(fetchClaimsByCarId(car.id));
        dispatch(fetchCarActiveBookings(car.id!));
        if (selectedClaim) {
          const { addedAt, tripStartedAt, tripFinishedAt } = selectedClaim;
          const from = getTheDayBefore(
            tripStartedAt || addedAt
          ).toISOString() as any;
          const to = getOneHourAfter(
            tripFinishedAt || addedAt
          ).toISOString() as any;
          dispatch(
            fetchVehicleEvents(
              token,
              car.id,
              from,
              to,
              ['damageClaim'],
              'CLAIM',
              false
            )
          );
        }
      }, 300);
    });
    if (shouldUpdateQuery) {
      const query = {
        vm: 'VEHICLE',
        carId: car.id,
      };
      setQueries(query);
    }
  };
};

export const selectCarAndSwitchToMapView =
  (car: FleetAdminCarData) => (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().userData.user;
    batch(() => {
      dispatch(selectCar(car));
      dispatch(selectArea(null));
      dispatch(fetchCarByIdIfNeeded(token, car.id!));
      dispatch(fetchCarConditionsIfNeeded(token, car.id!, false));
    });

    const query = {
      vm: 'VEHICLE',
      type: ViewType.MAP,
      carId: car.id,
    };

    setQueries(query);
  };

export const closeCarDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCar(null));
  dispatch(setCarDrawerActiveTab(0));
  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.carId;
  setQueries(existingParams, true);
};

export const closeCareDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCare(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.careId;
  setQueries(existingParams, true);
};

export const closeTripDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectTrip(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.tripId;
  setQueries(existingParams, true);
};

export const closeBookingDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectBooking(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.bookingId;
  setQueries(existingParams, true);
};

export const closeClaimDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectClaim(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.claimId;
  setQueries(existingParams, true);
};

export const selectAreaWithUIEffect =
  (area: AreaData) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(area)) dispatch(selectArea(area));
      dispatch(selectCar(null));
    });

    dispatch(fetchAreaById(area.id));

    const query = {
      vm: 'AREA',
      areaId: area.id,
    };

    setQueries(query);
  };

export const selectCategoryWithUIEffect =
  (category: VehicleCategoryData) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(category)) dispatch(selectCategory(category));
      dispatch(selectCar(null));
    });

    dispatch(fetchCategoryById(category?.id));

    const query = {
      vm: 'CATEGORIES',
      categoryId: category.id,
    };

    setQueries(query);
  };

export const selectPricingWithUIEffect =
  (pricing: PricingTableData) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(pricing)) dispatch(selectPricing(pricing));
    });

    dispatch(fetchPricingById(pricing.id));

    const query = {
      vm: 'PRICING',
      pricingId: pricing.id,
    };

    setQueries(query);
  };

export const selectPrivacyPolicyWithUIEffect =
  (privacyPolicy: PrivacyPolicyData) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(privacyPolicy)) {
        dispatch(selectPrivacyPolicy(privacyPolicy));
      }
      dispatch(closePurposeDrawerAndSetQueries());
    });

    dispatch(fetchPrivacyPolicyById(privacyPolicy.id));

    const query = {
      vm: 'PRIVACY_POLICY',
      policyId: privacyPolicy.id,
    };

    setQueries(query);
  };

export const selectPurposeWithUIEffect =
  (purpose: ProcessingPurposeData) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(purpose)) {
        dispatch(selectPurpose(purpose));
      }
      dispatch(closePolicyDrawerAndSetQueries());
    });

    dispatch(fetchPurposeById(purpose.id));

    const query = {
      vm: 'PRIVACY_POLICY',
      purposeId: purpose.id,
    };

    setQueries(query);
  };

export const selectReceiptWithUIEffect =
  (receipt: any) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(receipt)) dispatch(selectReceipt(receipt));
      dispatch(selectTrip(null));
      dispatch(selectUser(null));
      dispatch(fetchReceiptById(receipt.id));
    });

    const query = {
      vm: 'RECEIPTS',
      receiptId: receipt.id,
    };

    setQueries(query);
  };

export const selectProfileWithUIEffect =
  (profile: VehicleProfileInfoData) => (dispatch: Dispatch) => {
    batch(() => {
      if (!hasOnlyIdProperty(profile)) dispatch(selectProfile(profile));
    });

    dispatch(fetchProfileById(profile.id));

    const query = {
      vm: 'PROFILE',
      profileId: profile.id,
    };

    setQueries(query);
  };

export const selectCircleWithUIEffect =
  (circle: FleetAdminCircleData) => (dispatch: any) => {
    batch(() => {
      if (!hasOnlyIdProperty(circle)) dispatch(selectCircle(circle));
    });

    dispatch(fetchCircleById(circle.id!));

    const query = {
      vm: 'CIRCLE',
      circleId: circle.id,
    };

    setQueries(query);
  };

export const selectClaimWithUIEffect = (claim: any) => (dispatch: any) => {
  batch(() => {
    if (!hasOnlyIdProperty(claim)) dispatch(selectClaim(claim));
  });

  dispatch(fetchClaimById(claim.id));

  const query = {
    vm: 'CLAIM',
    claimId: claim.id,
  };

  setQueries(query);
};

export const selectCareWithUIEffect = (care: any) => (dispatch: any) => {
  batch(() => {
    if (!hasOnlyIdProperty(care)) dispatch(selectCare(care));
  });

  dispatch(fetchCareById(care.id));

  const query = {
    vm: 'CARE',
    careId: care.id,
  };

  setQueries(query);
};

export const selectTripWithUIEffect = (trip: any) => (dispatch: any) => {
  batch(() => {
    if (!hasOnlyIdProperty(trip)) dispatch(selectTrip(trip));
  });

  dispatch(fetchTripById(trip.id));

  const query = {
    vm: 'SHARE',
    tripId: trip.id,
  };

  setQueries(query);
};

export const selectBookingWithUIEffect = (booking: any) => (dispatch: any) => {
  batch(() => {
    if (!hasOnlyIdProperty(booking)) dispatch(selectBooking(booking));
  });

  dispatch(fetchBookingById(booking.id));

  const query = {
    vm: 'BOOKING',
    bookingId: booking.id,
  };

  setQueries(query);
};

export const selectUserWithUIEffect = (user: any) => (dispatch: any) => {
  batch(() => {
    dispatch(selectReceipt(null));
    if (!hasOnlyIdProperty(user)) dispatch(selectUser(user));
    dispatch(
      fetchUserById(user.id!, () => {
        batch(() => {
          dispatch(fetchUserDuplicates(user.id!));
          dispatch(
            fetchUserTransactions('account', user.id!, 0, 20, false, () => {
              dispatch(fetchUserTransactions('deposit', user.id!, 0, 20, true));
            })
          );
        });
      })
    );
  });

  const query = {
    vm: 'USERS',
    userId: user.id,
  };

  setQueries(query);
};

export const selectAdminWithUIEffect = (admin: any) => (dispatch: any) => {
  batch(() => {
    if (!hasOnlyIdProperty(admin)) dispatch(selectAdmin(admin));
  });

  dispatch(fetchAdminById(admin.id));

  const query = {
    vm: 'ADMINS',
    adminId: admin.id,
  };

  setQueries(query);
};

export const selectAreaAndSwitchToMapView =
  (area: AreaData) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectArea(area));
      dispatch(selectCar(null));
    });

    const query = {
      vm: 'AREA',
      type: ViewType.MAP,
      areaId: area.id,
    };

    setQueries(query);
  };

export const closeAreaDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectArea(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.areaId;
  setQueries(existingParams, true);
};

export const closecategoryDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCategory(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.categoryId;
  setQueries(existingParams, true);
};

export const closePricingDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectPricing(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.pricingId;
  setQueries(existingParams, true);
};

export const closeProfileDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectProfile(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.profileId;
  setQueries(existingParams, true);
};

export const closeReceiptDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectReceipt(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.receiptNo;
  setQueries(existingParams, true);
};

export const closeUsersDrawerAndSetQueries =
  (isDuplicate = false) =>
  (dispatch: Dispatch) => {
    if (isDuplicate) {
      dispatch(userDuplicateSuccess(null));
    } else {
      dispatch(selectUser(null));
    }

    const query = new URLSearchParams(window.location.search);
    const entries = query.entries();
    const existingParams = paramsToObject(entries);
    // @ts-ignore
    delete existingParams.userId;
    setQueries(existingParams, true);
  };

export const closePolicyDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectPrivacyPolicy(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.policyId;
  setQueries(existingParams, true);
};

export const closePurposeDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectPurpose(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.purposeId;
  setQueries(existingParams, true);
};

export const closeAdminsDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectAdmin(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.adminId;
  setQueries(existingParams, true);
};

export const closeCircleDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCircle(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.circleId;
  setQueries(existingParams, true);
};
