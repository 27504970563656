import deDuplicate from '../utils/deDuplicate';
import { Brand, FleetTripData } from '../@types';

const getBrands = (cares: FleetTripData[]): Brand[] | null => {
  if (cares == null) {
    return null;
  }

  const brandsWithDuplication = cares.map(
    ({ brandId, brandCode }): Brand => ({
      id: brandId!,
      code: brandCode!,
    })
  );
  return deDuplicate(brandsWithDuplication);
};

export default getBrands;
