import { alphabeticalSorterFor } from '../shared/TableLayout';
import Tag from 'antd/lib/tag';
import React from 'react';
import { formatISODuration, truncateText } from '../../utils';

const getPurposesColumns = (intl) => {
  const renderStartDate = (value) => (
    <Tag color="#e9d9c4" style={{ color: '#663403' }}>
      {intl.formatMessage({ id: `${value}` })}
    </Tag>
  );

  const renderGroups = (_: any[], record: any) => {
    return (
      <div>
        {record?.fieldGroupKeys?.map((group) => (
          <Tag
            key={group}
            style={{
              color: '#666668',
              backgroundColor: '#e7e7e7',
              margin: 2,
            }}
            className="merchant-tag"
          >
            {intl.formatMessage({ id: `${group}` })}
          </Tag>
        ))}
      </div>
    );
  };

  return [
    {
      title: intl.formatMessage({ id: 'fleetadmin.purpose.name' }),
      dataIndex: 'name',
      width: 200,
      fixed: 'left' as 'left',
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.purpose.startdate' }),
      dataIndex: 'activationEventKey',
      render: renderStartDate,
      sorter: alphabeticalSorterFor('activationEventKey'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.purpose.minduration' }),
      dataIndex: 'minDuration',
      render: (value) => <span>{formatISODuration(value)}</span>,
      sorter: alphabeticalSorterFor('minDuration'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.purpose.maxduration' }),
      dataIndex: 'maxDuration',
      render: (value) => <span>{formatISODuration(value)}</span>,
      sorter: alphabeticalSorterFor('maxDuration'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.purpose.legalbasis' }),
      dataIndex: 'legalBasis',
      width: 300,
      render: (value) => <span>{truncateText(value, 77)}</span>,
      sorter: alphabeticalSorterFor('legalBasis'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.purpose.fieldgroupkeys' }),
      dataIndex: 'fieldGroupKeys',
      render: renderGroups,
      sorter: alphabeticalSorterFor('fieldGroupKeys'),
    },
  ];
};

export default getPurposesColumns;
