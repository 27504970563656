import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import {
  selectDisplayColumnsForUsers,
  selectUserWithUIEffect,
} from '../../redux/store/ui/common';
import { FleetAdminCircleData } from '../../@types';
import {
  fetchTariffs,
  fetchUsers,
  selectDisplayUsers,
  selectSelectedUser,
  selectTotalUsers,
} from '../../redux/store/userData/users';
import { useIntl } from 'react-intl';
import withPaginatedQueryFetch from '../../hoc/withPaginatedQueryFetch';
import { selectAuth } from '../../redux/store/userData/user';
import { getFilteredColumns } from '../../utils';

const UsersTable = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayUsers = useSelector(selectDisplayUsers);
  const selectedUser = useSelector(selectSelectedUser);
  const usersNo = useSelector(selectTotalUsers);
  const user = useSelector(selectAuth);
  const displayColumns = useSelector(selectDisplayColumnsForUsers);
  const selectedBrand = user.brands?.[0];

  const selectedId = selectedUser ? selectedUser.id : null;
  const columns = getColumns({ intl, selectedBrand });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: FleetAdminCircleData) => {
    dispatch(selectUserWithUIEffect(record));
  };

  useEffect(() => {
    const brandIds = user?.brands?.map((brand) => brand?.id) || [];
    if (brandIds?.length) {
      dispatch(fetchTariffs(brandIds));
    }
  }, []);

  return (
    <TableLayout
      rowKey="user"
      columns={filteredColumns}
      dataSource={displayUsers!}
      selectedId={selectedId!}
      totalNumber={usersNo}
      onClickRow={onClickRow}
      current={props.pageNumber}
      onPageChanged={props.onPageChanged}
    />
  );
};

export default withPaginatedQueryFetch(UsersTable, fetchUsers);
