import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '../../components/shared/ListItem';
import LinkToArea from '../../components/shared/LinkToArea';
import LinkToVehicle from '../../components/shared/LinkToVehicle';
import { bookingInfoStyles } from './BookingInfo.styles';
import { formatDuration, getAreaById } from '../../utils';
import { selectAreas } from '../../redux/store/appData/areas';
import { selectCars } from '../../redux/store/userData/cars';
import {
  selectSelectedBooking,
  startBooking,
  updateBooking,
} from '../../redux/store/userData/trips';
import { selectCategories } from '../../redux/store/userData/categories';
import LinkToCategory from '../shared/LinkToCategory';
import { getVehicleById } from '../../utils/getVehicleById';
import { getCategoryById } from '../../utils/getCategoryById';
import formatDateTime from '../../utils/formatDateTime';
import { renderText } from '../shared/DrawerLayout/renderText';
import { selectAuth } from '../../redux/store/userData/user/selectors';
import { selectFleets } from '../../redux/store/appData/fleets';
import CarStatus from '../shared/CarStatus';
import { closeModal, openModal } from '../../redux/store/ui/common/actions';
import ListEditItem from '../shared/ListEditItem';
import { useIntl } from 'react-intl';
import { FleetBookingInputData } from '../../@types';
import LinkToPricing from '../shared/LinkToPricing';
import { selectPricings } from '../../redux/store/appData/pricings';
import LinkToTrip from '../shared/LinkToTrip';
import LoadingIcon from '../shared/LoadingIcon';
import { selectTripLoading } from '../../redux/store/userData/trips/selectors';
import LinkToUser from '../shared/LinkToUser';

const BookingInfo = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const areas = useSelector(selectAreas);
  const user = useSelector(selectAuth);
  const categories = useSelector(selectCategories);
  const {
    id,
    areaId,
    categoryId,
    userReferralCode,
    userId,
    userMerchantRef,
    confirmedCarId,
    merchantRef,
    created,
    duration,
    from,
    to,
    cancelledAt,
    cancelledBy,
    cancellationReason,
    status,
    requestedStatus,
    type,
    statusChangeNote,
    statusChangeBlockReasons,
    userEmail,
    insuranceName,
    circleName,
    pricingId,
    tripCode,
    tripId,
  } = useSelector(selectSelectedBooking);
  const vehicle = getVehicleById(cars, confirmedCarId);
  const area = getAreaById(areas, areaId);
  const pricings = useSelector(selectPricings);
  const fleets = useSelector(selectFleets);
  const isTripLoading = useSelector(selectTripLoading);
  const category = getCategoryById(categories, categoryId);
  const isEditable =
    user.permissions?.tripsManager && ['confirmed', 'offered'].includes(status);
  const selectedPricing = pricings?.find((item) => item.id === pricingId);

  const onStartBooking = () => {
    dispatch(startBooking(id));
  };

  const onConfirmBooking = () => {
    const updateBookingInput: FleetBookingInputData = {
      status: 'confirmed',
    };
    dispatch(updateBooking(id, updateBookingInput));
    dispatch(closeModal('BookingCircleEditModal'));
  };

  const handleVehicleEditButton = () => {
    dispatch(openModal('BookingVehicleEditModal'));
  };

  const handleUserEditButton = () => {
    dispatch(openModal('BookingUserEditModal'));
  };

  const handleInsuranceEditButton = () => {
    dispatch(openModal('BookingInsuranceEditModal'));
  };

  const handleCircleEditButton = () => {
    dispatch(openModal('BookingCircleEditModal'));
  };

  const handleStartEndEditButton = () => {
    dispatch(openModal('BookingStartEndEditModal'));
  };

  const onCancelBooking = () => {
    dispatch(openModal('BookingCancelModal'));
  };

  const renderBlockReasons = (blockReasons: Array<string>) => (
    <div className="booking-info-block-reasons">
      {blockReasons?.map((reason: string) => (
        <div title={reason} className="tooltip" key={reason}>
          <img
            src={`/images/blocked-reasons/${reason}.svg`}
            alt={reason}
            style={{ marginRight: '12px', width: '14px', height: '14px' }}
          />
        </div>
      ))}
      <style jsx>{bookingInfoStyles}</style>
    </div>
  );

  return (
    <>
      <div className="booking-info">
        {requestedStatus && (
          <div className="list-item-wrapper">
            <p className="label-status">Requested status</p>
            <CarStatus
              status={requestedStatus}
              style={{
                width: '130px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '3px',
                marginTop: '3px',
              }}
            />
          </div>
        )}
        {merchantRef && <ListItem label="Contract ID" value={merchantRef} />}
        <ListEditItem
          label="Car Plate"
          leftItem={
            <LinkToVehicle
              carId={confirmedCarId}
              text={vehicle?.plate}
              activeTab={2}
            />
          }
          rightItem={
            isEditable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleVehicleEditButton}
              />
            )
          }
        />
        {type && (
          <ListItem
            label="Type"
            value={
              <div style={{ display: 'flex' }}>
                {intl.formatMessage({ id: `fleetadmin.booking.type.${type}` })}
              </div>
            }
          />
        )}
        {(status === 'confirmed' || status === 'offered') &&
          type !== 'statusChange' && (
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.booking.user.label`,
              })}
              leftItem={
                <LinkToUser
                  userId={userId}
                  userCode={userReferralCode}
                  label={userEmail}
                />
              }
              rightItem={
                isEditable && (
                  <img
                    className="edit-button"
                    src={'/images/drawer/edit.svg'}
                    alt="edit"
                    onClick={handleUserEditButton}
                  />
                )
              }
            />
          )}
        {(status === 'confirmed' || status === 'offered') &&
          type !== 'statusChange' && (
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.booking.insurance.label`,
              })}
              leftItem={<span>{insuranceName}</span>}
              rightItem={
                isEditable && (
                  <img
                    className="edit-button"
                    src={'/images/drawer/edit.svg'}
                    alt="edit"
                    onClick={handleInsuranceEditButton}
                  />
                )
              }
            />
          )}
        {status === 'offered' && type !== 'statusChange' && (
          <ListEditItem
            label={intl.formatMessage({
              id: `fleetadmin.booking.circle.label`,
            })}
            leftItem={<span>{circleName}</span>}
            rightItem={
              isEditable && (
                <img
                  className="edit-button"
                  src={'/images/drawer/edit.svg'}
                  alt="edit"
                  onClick={handleCircleEditButton}
                />
              )
            }
          />
        )}
        {!(status === 'confirmed' || status === 'offered') &&
          userReferralCode && (
            <ListItem
              label={'User code'}
              value={<LinkToUser userId={userId} userCode={userReferralCode} />}
            />
          )}
        {category && (
          <ListItem
            label="Vehicle Category"
            value={<LinkToCategory category={category} text={category?.name} />}
          />
        )}
        {selectedPricing && (
          <ListEditItem
            label={intl.formatMessage({
              id: 'fleetadmin.booking.pricing',
            })}
            leftItem={
              <LinkToPricing
                text={selectedPricing?.name}
                pricing={selectedPricing}
              />
            }
          />
        )}
        {tripCode && (
          <ListEditItem
            label={intl.formatMessage({
              id: 'fleetadmin.receipts.table.tripcode',
            })}
            leftItem={
              <LinkToTrip tripId={tripId} tripCode={tripCode} service={null} />
            }
            rightItem={isTripLoading ? <LoadingIcon /> : null}
          />
        )}
        <ListItem
          label="Area"
          value={<LinkToArea area={area} text={area?.name} />}
        />
        {insuranceName && (
          <ListItem label="Insurance" value={renderText(insuranceName)} />
        )}
        {statusChangeNote && (
          <ListItem
            label={intl.formatMessage({
              id: `fleetadmin.booking.statuschange.label`,
            })}
            value={renderText(statusChangeNote)}
          />
        )}
        {statusChangeBlockReasons?.length > 0 && (
          <ListItem
            label={intl.formatMessage({
              id: `fleetadmin.booking.blockreasons.label`,
            })}
            value={renderBlockReasons(statusChangeBlockReasons)}
          />
        )}
        <ListItem label={'Created At'} value={formatDateTime(created)} />
        <ListEditItem
          label={'From'}
          leftItem={<span>{formatDateTime(from)}</span>}
          rightItem={
            isEditable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleStartEndEditButton}
              />
            )
          }
        />
        <ListEditItem
          label={'To'}
          leftItem={<span>{formatDateTime(to)}</span>}
          rightItem={
            isEditable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleStartEndEditButton}
              />
            )
          }
        />
        <ListItem label={'Duration'} value={formatDuration(duration)} />
        {user.permissions?.tripsManager &&
          status === 'confirmed' &&
          fleets?.find((fleet) => fleet?.id === vehicle?.fleetId)
            ?.offlineBookingsEnabled && (
            <div className="add-booking-button-wrapper">
              <div className="blue-action" onClick={onStartBooking}>
                {intl.formatMessage({
                  id: `fleetadmin.booking.start`,
                })}
              </div>
            </div>
          )}

        {user.permissions?.tripsManager && status === 'offered' && (
          <div className="add-booking-button-wrapper">
            <div className="blue-action" onClick={onConfirmBooking}>
              {intl.formatMessage({
                id: `fleetadmin.booking.confirm`,
              })}
            </div>
          </div>
        )}
        {cancelledAt && (
          <ListItem label="Cancelled At" value={formatDateTime(cancelledAt)} />
        )}
        {cancelledBy && (
          <ListItem label="Cancelled By" value={renderText(cancelledBy)} />
        )}
        {cancellationReason && (
          <ListItem
            label="Cancellation Reason"
            value={renderText(cancellationReason)}
          />
        )}
        {isEditable && (
          <div className="booking-info-cancel-button" onClick={onCancelBooking}>
            {intl.formatMessage({
              id: `fleetadmin.booking.cancel`,
            })}
          </div>
        )}
      </div>
      <style jsx>{bookingInfoStyles}</style>
    </>
  );
};

export default BookingInfo;
