import { createAction, createErrorAction } from '../../../helpers';
import {
  ErrorResponse,
  PrivacyPolicyData,
  ProcessingPurposeData,
  ActivationEventData,
} from '../../../../@types';
import { PrivacyPolicyTypes } from '../../../../@types/enums';

export const privacyPoliciesRequest = () =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICIES_REQUEST);

export const privacyPoliciesSuccess = (payload: PrivacyPolicyData[]) =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICIES_SUCCESS, payload);

export const privacyPoliciesFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.PRIVACY_POLICIES_FAILURE, error);

export const collectionNamesRequest = () =>
  createAction(PrivacyPolicyTypes.COLLECTION_NAMES_REQUEST);

export const collectionNamesSuccess = (payload: string[]) =>
  createAction(PrivacyPolicyTypes.COLLECTION_NAMES_SUCCESS, payload);

export const collectionNamesFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.COLLECTION_NAMES_FAILURE, error);

export const activationEventsRequest = () =>
  createAction(PrivacyPolicyTypes.ACTIVATION_EVENTS_REQUEST);

export const activationEventsSuccess = (payload: ActivationEventData[]) =>
  createAction(PrivacyPolicyTypes.ACTIVATION_EVENTS_SUCCESS, payload);

export const activationEventsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.ACTIVATION_EVENTS_FAILURE, error);

export const groupsRequest = () =>
  createAction(PrivacyPolicyTypes.GROUPS_REQUEST);

export const groupsSuccess = (payload: ActivationEventData[]) =>
  createAction(PrivacyPolicyTypes.GROUPS_SUCCESS, payload);

export const groupsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.GROUPS_FAILURE, error);

export const setDisplayPrivacyPolicies = (payload: PrivacyPolicyData[]) =>
  createAction(PrivacyPolicyTypes.SET_DISPLAY_PRIVACY_POLICY, payload);

export const selectPrivacyPolicy = (payload: PrivacyPolicyData | null) =>
  createAction(PrivacyPolicyTypes.SELECT_PRIVACY_POLICY, payload);

export const selectPurpose = (payload: ProcessingPurposeData | null) =>
  createAction(PrivacyPolicyTypes.SELECT_PURPOSE, payload);

export const privacyPolicyUpdateRequest = () =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICY_UPDATE_REQUEST);

export const privacyPolicyUpdateSuccess = (payload: PrivacyPolicyData) =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICY_UPDATE_SUCCESS, payload);

export const privacyPolicyUpdateFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.PRIVACY_POLICY_UPDATE_FAILURE, error);

export const privacyPolicyDeleteRequest = () =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICY_DELETE_REQUEST);

export const privacyPolicyDeleteSuccess = (payload) =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICY_DELETE_SUCCESS, payload);

export const privacyPolicyDeleteFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.PRIVACY_POLICY_DELETE_FAILURE, error);

export const privacyPolicyRequest = () =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICY_REQUEST);

export const privacyPolicySuccess = (payload: any[]) =>
  createAction(PrivacyPolicyTypes.PRIVACY_POLICY_SUCCESS, payload);

export const privacyPolicyFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.PRIVACY_POLICY_FAILURE, error);

export const copyPrivacyPolicySuccess = (payload: any[]) =>
  createAction(PrivacyPolicyTypes.COPY_PRIVACY_POLICY_SUCCESS, payload);

export const createPurposeSuccess = (payload: any[]) =>
  createAction(PrivacyPolicyTypes.CREATE_PURPOSE_SUCCESS, payload);

export const archivePurposeSuccess = (payload: any) =>
  createAction(PrivacyPolicyTypes.ARCHIVE_PURPOSE_SUCCESS, payload);

export const purposeSuccess = (payload: any[]) =>
  createAction(PrivacyPolicyTypes.PURPOSE_SUCCESS, payload);

export const purposeUpdateRequest = () =>
  createAction(PrivacyPolicyTypes.PURPOSE_UPDATE_REQUEST);

export const purposeUpdateSuccess = (payload: PrivacyPolicyData) =>
  createAction(PrivacyPolicyTypes.PURPOSE_UPDATE_SUCCESS, payload);

export const purposeUpdateFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PrivacyPolicyTypes.PURPOSE_UPDATE_FAILURE, error);
