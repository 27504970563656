import { Reducer } from '../../../../@types';
import { CategoriesTypes } from '../../../../@types/enums';
import { initialState, CategoriesState } from './initialState';
// @ts-ignore
export const categoriesReducer: Reducer<CategoriesState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case CategoriesTypes.CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CategoriesTypes.CATEGORIES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case CategoriesTypes.CATEGORY_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state?.display
          ? [
              ...state.display.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
      };
    case CategoriesTypes.CATEGORIES_FAILURE:
      return {
        ...state,

        error: action.error,
        loading: false,
      };
    case CategoriesTypes.SELECT_CATEGORY:
      return {
        ...state,
        selected: action.payload,
      };
    case CategoriesTypes.SET_DISPLAY_CATEGORIES:
      return {
        ...state,
        display: action.payload,
      };
    default:
      return state;
  }
};
