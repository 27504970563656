import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  p {
    margin-bottom: 0;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 14px;
    width: 130px;
    text-align: center;
    border-radius: 12px;
    color: #fff;
  }
  p.reserved {
    background: ${colors.trips.reserved};
  }
  p.in_progress {
    background: ${colors.trips.inProgress};
  }
  p.done {
    background: ${colors.trips.finished};
  }
  p.closed {
    background: ${colors.trips.finished};
  }
  p.expired {
    background: ${colors.trips.cancelled};
  }
  p.cancelled {
    background: ${colors.trips.cancelled};
  }
`;

export default style;
