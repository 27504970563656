import { RootState } from '../../../config';

export const selectPrivacyPolicies = (state: RootState): any =>
  state.appData.privacyPolicies.entities;

export const selectDisplayPrivacyPolicy = (state: RootState): any =>
  state.appData.privacyPolicies.display;

export const selectSelectedPrivacyPolicy = (state: RootState): any =>
  state.appData.privacyPolicies.selected;

export const selectSelectedPurpose = (state: RootState): any =>
  state.appData.privacyPolicies.selectedPurpose;

export const selectPrivacyPolicyLoading = (state: RootState): boolean =>
  state.appData.privacyPolicies.loading;

export const selectCollectionNames = (state: RootState): any =>
  state.appData.privacyPolicies.collectionNames;

export const selectActivationEvents = (state: RootState): any =>
  state.appData.privacyPolicies.activationEvents;

export const selectFieldGroups = (state: RootState): any =>
  state.appData.privacyPolicies.groups;
