import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './InvoiceItem.styles';
import { fetchReceiptById } from '../../redux/store/userData/receipts/thunk';
import { selectAuth } from '../../redux/store/userData/user';
import { notification } from 'antd';
import { selectReceiptLoading } from '../../redux/store/userData/receipts';
import LoadingIcon from '../shared/LoadingIcon';
import { selectSelectedDrawersCount } from '../../redux/store/ui/common';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface Props {
  invoiceName: string;
  invoiceUri: string;
  showDownloadButton?: boolean;
  showCopyInvoiceUrl?: boolean;
}

const InvoiceItem: React.FC<Props> = ({
  invoiceName,
  invoiceUri,
  showDownloadButton,
  showCopyInvoiceUrl,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);
  const isPaymentManager = user?.permissions?.paymentManager;
  const receiptLoading = useSelector(selectReceiptLoading);

  const onClickDownload = () => {
    window.open(invoiceUri);
  };

  const onClickInvoice = () => {
    if (isPaymentManager && !receiptLoading && openedDrawersCount === 1) {
      dispatch(fetchReceiptById(invoiceName));
    }
  };

  const copyInvoiceLink = () => {
    const el = document.createElement('textarea');
    // @ts-ignore
    el.value = invoiceUri;
    document.body.appendChild(el);
    el.focus();
    el.select();
    try {
      document.execCommand('copy');
      document.body.removeChild(el);
      notification.success({
        message: 'Invoice link Copied',
        style: {
          whiteSpace: 'pre',
        },
      });
    } catch (err) {
      console.log('Oops, unable to copy');
    }
  };

  return (
    <>
      {isPaymentManager && openedDrawersCount < 2 ? (
        <div className="wrapper">
          <span
            className={isPaymentManager ? 'link' : 'value-wrapper'}
            onClick={onClickInvoice}
          >
            {invoiceName}
            {receiptLoading && <LoadingIcon />}
          </span>
          <div className="receipt-actions">
            {showCopyInvoiceUrl && (
              <img
                src={'/images/car-editor/location.svg'}
                alt="location"
                onClick={copyInvoiceLink}
                style={{ cursor: 'pointer', marginRight: 10 }}
              />
            )}
            {showDownloadButton && (
              <img
                onClick={onClickDownload}
                src={'/images/drawer/download.svg'}
                alt="download"
              />
            )}
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <Link
            replace={false}
            to={{
              pathname: '/dashboard',
              search: `?vm=RECEIPTS&type=RECEIPT&pageSize=50&pageNumber=1&receiptId=${invoiceName}`,
            }}
            target="_blank"
          >
            <a
              className="external-link-wrapper"
              target="_blank"
              rel="noreferrer"
            >
              <span className="link">{invoiceName}</span>
            </a>
          </Link>
          <div className="receipt-actions">
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginRight: '10px' }}
            />
            {showDownloadButton && (
              <img
                onClick={onClickDownload}
                src={'/images/drawer/download.svg'}
                alt="download"
                style={{ fontSize: '18px' }}
              />
            )}
          </div>
        </div>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

InvoiceItem.defaultProps = {
  showDownloadButton: false,
  showCopyInvoiceUrl: false,
};

export default InvoiceItem;
