const serviceTypes = [
  'safetyCheck',
  'washInside',
  'cleanOutsideDry',
  'washOutside',
  'refuel',
  'relocate',
  'batteryChangeSimple',
  'batteryChangeOpening',
];

export default serviceTypes;
