import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import PurposeDrawerBody from './PurposeDrawerBody';
import { closePurposeDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectSelectedPurpose } from '../../redux/store/appData/privacyPolicy';

const PurposeDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedPurpose = useSelector(selectSelectedPurpose);
  const visible = selectedPurpose != null;

  const handleOnClose = () => {
    dispatch(closePurposeDrawerAndSetQueries());
  };

  return (
    selectedPurpose && (
      <DrawerLayout
        visible={visible}
        title={selectedPurpose?.id!!}
        body={<PurposeDrawerBody selectedPurpose={selectedPurpose} />}
        // nestedDrawers={
        //   <>{viewMode !== ViewMode.RECEIPTS && <ReceiptDrawer />}</>
        // }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default PurposeDrawer;
