import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, DatePicker, Checkbox } from 'antd';
import styles from './UserIdentityModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import { UserDataOutput } from '../../../@types';
import dayjs from 'dayjs';

const canVerifyIdentity = (
  firstName: string,
  lastName: string,
  birthDate: string,
  user: UserDataOutput
): boolean => {
  if (user && user.identityApprovedAt) return false;
  return firstName?.trim() !== '' && lastName?.trim() !== '' && !!birthDate;
};

interface UserInput {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  birthPlace?: string;
  identityApprovedManually?: boolean;
}

const UserIdentityModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const selectedUser = useSelector(selectSelectedUser);
  const [firstName, setFirstName] = useState(selectedUser?.firstName || '');
  const [lastName, setLastName] = useState(selectedUser?.lastName || '');
  const [identityApproved, setIdentityApproved] = useState(
    selectedUser?.identityApprovedAt != null
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    selectedUser?.dateOfBirth || null
  ) as any;

  const [birthPlace, setBirthPlace] = useState(selectedUser?.birthPlace || '');

  const visible = useSelector(getIsVisibleModal('UserIdentityModal'));

  const handleCancel = () => {
    dispatch(closeModal('UserIdentityModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    let userInput: UserInput = {};

    if (firstName !== selectedUser?.firstName) {
      userInput.firstName = firstName;
    }
    if (lastName !== selectedUser.lastName) {
      userInput.lastName = lastName;
    }
    if (dateOfBirth && dateOfBirth !== selectedUser?.dateOfBirth) {
      userInput.dateOfBirth = dateOfBirth;
    }
    if (birthPlace !== selectedUser.birthPlace) {
      userInput.birthPlace = birthPlace;
    }
    if (identityApproved && selectedUser?.identityApprovedAt == null) {
      userInput.identityApprovedManually = identityApproved;
    }
    if (Object.keys(userInput).length > 0) {
      dispatch(updateUser(selectedUser.id, userInput));
      handleCancel();
    }
  };

  const onClickIdentityApproved = (e: any) => {
    setIdentityApproved(e.target.checked);
  };

  useEffect(() => {
    if (visible) {
      setFirstName(selectedUser?.firstName || '');
      setLastName(selectedUser?.lastName || '');
      setBirthPlace(selectedUser?.birthPlace || '');
      setDateOfBirth(selectedUser?.dateOfBirth || null);
    }
  }, [visible]);

  const disabled = !canVerifyIdentity(
    firstName,
    lastName,
    dateOfBirth,
    selectedUser
  );

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="user-identity-contents">
          <div className="user-identity-title">
            {intl.formatMessage({
              id: `fleetadmin.users.info.identity`,
            })}
          </div>
          <div className="user-identity-input-container">
            <div className="user-identity-input-row-container">
              <div style={{ marginRight: 8 }}>
                <div className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.firstname`,
                  })}
                </div>
                <Input
                  value={firstName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.firstname`,
                  })}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{ marginRight: 12 }}
                  disabled={
                    selectedUser?.firstNameVerified &&
                    !selectedUser?.legalFirstName
                  }
                />
              </div>
              <div>
                <div className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.lastname`,
                  })}
                </div>
                <Input
                  value={lastName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.lastname`,
                  })}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={
                    selectedUser?.lastNameVerified &&
                    !selectedUser?.legalLastName
                  }
                />
              </div>
            </div>
            <div className="user-identity-date">
              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.birthday`,
                })}
              </div>
              <DatePicker
                format={dateFormat}
                getPopupContainer={(triggerNode: HTMLElement) => {
                  return triggerNode.parentNode as HTMLElement;
                }}
                style={{ width: '100%' }}
                value={dateOfBirth ? dayjs(dateOfBirth) : undefined}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.birthday`,
                })}
                onChange={(date, dateString) => setDateOfBirth(dateString)}
                disabled={selectedUser?.dateOfBirthVerified}
              />
            </div>
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.birthplace`,
              })}
            </div>
            <Input
              value={birthPlace}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.birthplace`,
              })}
              onChange={(e) => setBirthPlace(e.target.value)}
              disabled={selectedUser?.birthPlaceVerified}
            />
            <Checkbox
              disabled={disabled}
              style={{ marginBottom: 8, marginTop: 8 }}
              checked={identityApproved}
              onChange={onClickIdentityApproved}
            >
              {intl.formatMessage({
                id: 'fleetadmin.users.info.identityApproved',
              })}
            </Checkbox>
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};
export default UserIdentityModal;
