const FRM_PARTNERS = [
  { label: 'Anundo', value: '117_anundoquartier_schnittstelle' },
  { label: 'BPD', value: '117_bpdquartier_schnittstelle' },
  { label: 'Das E', value: '117_dase_schnittstelle' },
  { label: 'evohaus', value: '117_evoquartier_schnittstelle' },
  { label: 'Familienheim', value: '117_famiheimquartier_schnittstelle' },
  { label: 'Flüwo', value: '117_fluewoquartier_schnittstelle' },
  { label: 'GBG', value: '117_gbgquartier_schnittstelle' },
  { label: 'GWH', value: '117_gwhquartier_schnittstelle' },
  { label: 'IHR HAUS Sahle', value: '117_ihsahlequartier_schnittstelle' },
  { label: 'Immprinzip', value: '117_immprinzipquartier_schnittstelle' },
  { label: 'Innovatio / Profund', value: '117_innoproquartier_schnittstelle' },
  {
    label: 'Mannheimer Wohnwerte Spinelli',
    value: '117_mwspinelliquartier_schnittstelle',
  },
  { label: 'MVV Energie', value: '117_mvvquartier_schnittstelle' },
  { label: 'NeighborWood', value: '117_neighwquartier_schnittstelle' },
  { label: 'RVI', value: '117_rviquartier_schnittstelle' },
  { label: 'Oikos eG', value: '117_oikosquartier_schnittstelle' },
  { label: 'Quartiersmanufaktur', value: '117_qumanuquartier_schnittstelle' },
  { label: 'Sahle Wohnen', value: '117_sawoquartier_schnittstelle' },
  { label: 'Traumhaus', value: '117_trahauquartier_schnittstelle' },
  {
    label: 'Vermietungsgenossenschaft Ludwig-Frank',
    value: '117_vglfquartier_schnittstelle',
  },
  {
    label: 'WohnWerk Mannheim e.G.',
    value: '117_wohnrnquartier_schnittstelle',
  },
];

const FRM_PARTNER_TARIFFS = [
  {
    label: 'Franklin Partner Tarif',
    value: '117_franklinpartner_schnittstelle',
  },
  {
    label: 'Franklin Partner+ Tarif',
    value: '117_franklinpartnerplus_schnittstelle',
  },
  {
    label: 'Franklin Partner Fix',
    value: '117_franklinfixpartner_schnittstelle',
  },
];

const FRM_NONE_PARTNER_TARIFFS = [
  { label: 'Franklin-mobil Basic Tarif', value: '117_franklin_schnittstelle' },
  { label: 'Franklin + Tarif', value: '117_franklinplus_schnittstelle' },
  { label: 'Franklin Fix Tarif', value: '117_franklinfix_schnittstelle' },
];

const FRM_PARTNER_AREAS = [
  { label: 'Außerhalb / Ich weiß es nicht', value: '117_aus_schnittstelle' },
  { label: 'Brühl', value: '117_bruh_schnittstelle' },
  { label: 'Quartier Franklin', value: '117_qfr_schnittstelle' },
  { label: 'Quartier Spinelli', value: '117_qsp_schnittstelle' },
  { label: 'Mannheim', value: '117_mannheim_schnittstelle' },
  { label: 'Schriesheim', value: '117_schriesheim_schnittstelle' },
];

const FRM_NONE_PARTNER_AREAS = [
  {
    label: 'Außerhalb / Ich weiß es nicht',
    value: '117_ausohnep_schnittstelle',
  },
  { label: 'Brühl', value: '117_bruhohnep_schnittstelle' },
  { label: 'Quartier Franklin', value: '117_qfrohnep_schnittstelle' },
  { label: 'Quartier Spinelli', value: '117_qspohnep_schnittstelle' },
  { label: 'Mannheim', value: '117_mannheimohnep_schnittstelle' },
  { label: 'Schriesheim', value: '117_schriesheimohnep_schnittstelle' },
];

const FRM_NONE_PARTNER_UPSELLING = [
  { label: 'yes', value: '117_sipakja_schnittstelle' },
  { label: 'no', value: '117_sipaknein_schnittstelle' },
];

const FRM_PARTNER_UPSELLING = [
  { label: 'yes', value: '117_sipakpartnerja_schnittstelle' },
  { label: 'no', value: '117_sipakpartnernein_schnittstelle' },
];

const FRM_NONE_PARTNER_VR_BANK = [
  { label: 'yes', value: '117_vrbankja_schnittstelle' },
  { label: 'no', value: '117_vrbanknein_schnittstelle' },
];

const FRM_PARTNER_VR_BANK = [
  { label: 'yes', value: '117_vrbankpartnerja_schnittstelle' },
  { label: 'no', value: '117_vrbankpartnernein_schnittstelle' },
];

const FRM_NONE_PARTNER_REFERRAL = [
  { label: 'yes', value: '117_geworben_schnittstelle_ja' },
  { label: 'no', value: '117_nicht_geworben_schnittstelle' },
];

const FRM_PARTNER_REFERRAL = [
  { label: 'yes', value: '117_geworbenpartner_schnittstelle_ja' },
  { label: 'no', value: '117_nicht_geworbenpartner_schnittstelle' },
];

export const FRM_PRODUCT_OPTIONS = [
  // None Partner
  {
    productID: '117_privatkunden-schnittstelle',
    productOptions: [
      {
        name: 'Tariff',
        labelKey: 'frm.product.options.tariff',
        type: 'selection',
        options: FRM_NONE_PARTNER_TARIFFS,
      },
      {
        name: 'Upselling',
        labelKey: 'frm.product.options.upselling',
        type: 'selection',
        options: FRM_NONE_PARTNER_UPSELLING,
      },
      {
        name: 'Area',
        labelKey: 'frm.product.options.area',
        type: 'selection',
        options: FRM_NONE_PARTNER_AREAS,
      },
      {
        name: 'Referral',
        labelKey: 'frm.product.options.referral',
        type: 'selection',
        options: FRM_NONE_PARTNER_REFERRAL,
      },
      {
        name: 'VRBank',
        labelKey: 'frm.product.options.vrbank',
        type: 'selection',
        options: FRM_NONE_PARTNER_VR_BANK,
      },
    ],
  },

  // Partner
  {
    productID: '117_privatkunden-schnittstelle-p',
    productOptions: [
      {
        name: 'Tariff',
        labelKey: 'frm.product.options.tariff',
        type: 'selection',
        options: FRM_PARTNER_TARIFFS,
      },
      {
        name: 'Upselling',
        labelKey: 'frm.product.options.upselling',
        type: 'selection',
        options: FRM_PARTNER_UPSELLING,
      },
      {
        name: 'Partners',
        labelKey: 'frm.product.options.partner',
        type: 'selection',
        options: FRM_PARTNERS,
      },
      {
        name: 'Area',
        labelKey: 'frm.product.options.area',
        type: 'selection',
        options: FRM_PARTNER_AREAS,
      },
      {
        name: 'Referral',
        labelKey: 'frm.product.options.referral',
        type: 'selection',
        options: FRM_PARTNER_REFERRAL,
      },
      {
        name: 'VRBank',
        labelKey: 'frm.product.options.vrbank',
        type: 'selection',
        options: FRM_PARTNER_VR_BANK,
      },
    ],
  },
];
