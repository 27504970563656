import {
  ActivationEventData,
  ErrorResponse,
  FieldGroupData,
  PrivacyPolicyData,
  ProcessingPurposeData,
} from '../../../../@types';

export interface PricingsState {
  entities: PrivacyPolicyData[] | null;
  display: PrivacyPolicyData[] | null;
  selected: PrivacyPolicyData | null;
  selectedPurpose: ProcessingPurposeData | null;
  collectionNames: string[] | null;
  activationEvents: ActivationEventData[] | null;
  groups: FieldGroupData[] | null;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  selectedPurpose: null,
  collectionNames: null,
  activationEvents: null,
  groups: null,
  error: null,
  loading: false,
};
