import { defaultSelectedCarColumns } from '../../../../components/ColumnSelector/carColumns';
import { defaultSelectedAreaColumns } from '../../../../components/ColumnSelector/areaColumns';
import { defaultSelectedCareColumns } from '../../../../components/ColumnSelector/careColumns';
import { defaultSelectedTripColumns } from '../../../../components/ColumnSelector/tripColumns';
import { defaultSelectedBookingColumns } from '../../../../components/ColumnSelector/bookingColumns';
import { defaultSelectedClaimColumns } from '../../../../components/ColumnSelector/claimColumns';
import { defaultSelectedCategoryColumns } from '../../../../components/ColumnSelector/categoryColumns';
import { defaultSelectedRecordColumns } from '../../../../components/ColumnSelector/recordColumns';
import { defaultSelectedCircleColumns } from '../../../../components/ColumnSelector/circleColumns';
import { defaultSelectedPricingColumns } from '../../../../components/ColumnSelector/pricingColumns';
import { defaultSelectedProfileColumns } from '../../../../components/ColumnSelector/profileColumns';
import { defaultSelectedUsersColumns } from '../../../../components/ColumnSelector/usersColumns';
import { defaultSelectedAdminsColumns } from '../../../../components/ColumnSelector/adminsColumns';
import { ViewMode } from '../../../../hooks/useViewMode';
import { ViewType } from '../../../../hooks/useViewType';
import { defaultSelectedTimeTableColumns } from '../../../../components/ColumnSelector/timeTableColumns';
import { defaultSelectedReceiptsColumns } from '../../../../components/ColumnSelector/receiptsColumns';
import { defaultSelectedPrivacyPolicyColumns } from '../../../../components/ColumnSelector/privacyPolicyColumns';
export interface StatusModal {
  statuses?: string[];
  defaultValue?: string;
  textkeyPath?: string;
  placeHolder?: string;
  onConfirm?: (string) => void;
  onReset?: () => void;
}
export interface CommonState {
  appliedFiltersHeight: number;
  displayColumns: {
    car: string[];
    timeTable: string[];
    area: string[];
    care: string[];
    trip: string[];
    booking: string[];
    claim: string[];
    category: string[];
    pricing: string[];
    privacyPolicy: string[];
    record: string[];
    circle: string[];
    profile: string[];
    users: string[];
    admins: string[];
    receipts: string[];
  };
  visibleModals: string[];
  confirmModal: any;
  statusModal?: StatusModal;
  viewMode: ViewMode;
  viewType: ViewType;
}

export const initialState: CommonState = {
  appliedFiltersHeight: 8,
  displayColumns: {
    car: [...defaultSelectedCarColumns],
    timeTable: [...defaultSelectedTimeTableColumns],
    area: [...defaultSelectedAreaColumns],
    care: [...defaultSelectedCareColumns],
    trip: [...defaultSelectedTripColumns],
    booking: [...defaultSelectedBookingColumns],
    claim: [...defaultSelectedClaimColumns],
    category: [...defaultSelectedCategoryColumns],
    pricing: [...defaultSelectedPricingColumns],
    privacyPolicy: [...defaultSelectedPrivacyPolicyColumns],
    record: [...defaultSelectedRecordColumns],
    circle: [...defaultSelectedCircleColumns],
    profile: [...defaultSelectedProfileColumns],
    users: [...defaultSelectedUsersColumns],
    admins: [...defaultSelectedAdminsColumns],
    receipts: [...defaultSelectedReceiptsColumns],
  },
  visibleModals: [],
  confirmModal: null,
  statusModal: null,
  viewMode: ViewMode.VEHICLE,
  viewType: ViewType.TABLE,
};
