import React from 'react';
import { alphabeticalSorterFor, renderDateTime } from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import { getStatusColor } from '../../utils';
import Tag from 'antd/lib/tag';
import { renderText } from '../shared/DrawerLayout/renderText';

const getColumns = (intl) => {
  const renderStatus = (status) => (
    <Tag color={getStatusColor(status)}>{status}</Tag>
  );

  const renderBrand = (brand) => {
    return (
      <Tag
        style={{
          borderColor: '#d3d3d3',
          backgroundColor: 'rgba(242, 242, 242, 0.6)',
          margin: 2,
        }}
        className="merchant-tag"
      >
        {brand}
      </Tag>
    );
  };

  return [
    {
      title: intl.formatMessage({ id: 'fleetadmin.policy.name' }),
      dataIndex: 'name',
      width: isDesktop() ? 200 : 150,
      render: renderText,
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.policy.status' }),
      dataIndex: 'status',
      width: isDesktop() ? 220 : 150,
      render: renderStatus,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.policy.brandname',
      }),
      dataIndex: 'brandName',
      width: isDesktop() ? 220 : 150,
      render: renderBrand,
      sorter: alphabeticalSorterFor('brandName'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.policy.lastmodifiedat' }),
      dataIndex: 'lastModified',
      width: isDesktop() ? 240 : 150,
      render: renderDateTime,
      sorter: alphabeticalSorterFor('lastModified'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.policy.publishedat' }),
      dataIndex: 'publishedFirstTimeAt',
      width: isDesktop() ? 220 : 150,
      render: renderDateTime,
      sorter: alphabeticalSorterFor('publishedFirstTimeAt'),
    },
  ];
};

export default getColumns;
