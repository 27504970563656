import { Reducer } from '../../../../@types';
import { AreasTypes } from '../../../../@types/enums';
import { initialState, AreaState } from './initialState';
// @ts-ignore
export const areasReducer: Reducer<AreaState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case AreasTypes.AREAS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AreasTypes.AREAS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case AreasTypes.AREAS_FAILURE:
      return {
        ...state,

        error: action.error,
        loading: false,
      };
    case AreasTypes.SELECT_AREA:
      return {
        ...state,
        selected: action.payload,
      };
    case AreasTypes.SET_DISPLAY_AREAS:
      return {
        ...state,
        display: action.payload,
      };
    case AreasTypes.DISABLE_AREA_REQUEST:
      return {
        ...state,
        disabling: true,
      };
    case AreasTypes.DISABLE_AREA_SUCCESS:
      return {
        ...state,

        entities: state?.entities?.filter(
          (area) => area.id !== action.payload.id
        ),

        display: state?.display?.filter(
          (area) => area.id !== action.payload.id
        ),
        disabling: false,
      };
    case AreasTypes.DISABLE_AREA_FAILURE:
      return {
        ...state,
        disabling: false,
      };
    case AreasTypes.UPLOAD_AREA_FILE_REQUEST:
      return {
        ...state,
        uploadingFile: true,
      };
    case AreasTypes.UPLOAD_AREA_FILE_SUCCESS:
      return {
        ...state,
        entities: state?.entities?.map((area) => {
          if (area.id === action.payload.id) {
            return action.payload;
          }
          return area;
        }),
        display: state?.display?.map((area) => {
          if (area.id === action.payload.id) {
            return action.payload;
          }
          return area;
        }),
        selected: action.payload,
        uploadingFile: false,
      };
    case AreasTypes.UPLOAD_AREA_FILE_FAILURE:
      return {
        ...state,
        uploadingFile: false,
      };
    case AreasTypes.ARCHIVE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AreasTypes.ARCHIVE_AREA_SUCCESS:
      return {
        ...state,
        entities: state?.entities?.filter(
          (area) => area.id !== action.payload.id
        ),
        display: state?.display?.filter(
          (area) => area.id !== action.payload.id
        ),
        selected: null,
        loading: false,
      };
    case AreasTypes.ARCHIVE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AreasTypes.CREATE_AREA_REQUEST:
      return {
        ...state,
        creatingArea: true,
      };
    case AreasTypes.CREATE_AREA_SUCCESS:
      return {
        ...state,
        entities: [action.payload, ...(state.entities ? state.entities : [])],
        display: [action.payload, ...(state.display ? state.display : [])],
        selected: action.payload,
        creatingArea: false,
      };
    case AreasTypes.AREA_BY_ID_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state?.display
          ? [
              ...state.display.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
      };
    case AreasTypes.CREATE_AREA_FAILURE:
      return {
        ...state,
        creatingArea: false,
        error: action.error,
      };
    case AreasTypes.ADD_HIDE_ON_MAP:
      return {
        ...state,
        hideOnMap: [...state.hideOnMap, action.payload],
      };
    case AreasTypes.REMOVE_HIDE_ON_MAP:
      return {
        ...state,

        hideOnMap: state.hideOnMap.filter(
          (areaId) => areaId !== action.payload
        ),
      };
    default:
      return state;
  }
};
