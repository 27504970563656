import React from 'react';
import { InputNumber, Radio } from 'antd';
import styles from './NumberSearch.styles';
import { useIntl } from 'react-intl';

type NumberSearchProps = {
  isMoreThan: boolean;
  unit?: string;
  onChangeValue: (arg: any) => void;
  onChangeRadio: (arg: any) => void;
};

const NumberSearch: React.FC<NumberSearchProps> = ({
  isMoreThan,
  unit = '',
  onChangeValue,
  onChangeRadio,
}) => {
  const intl = useIntl();
  const moreLabel = intl.formatMessage({
    id: 'fleetadmin.filter.numberSearch.moreThan',
  });
  const lessLabel = intl.formatMessage({
    id: 'fleetadmin.filter.numberSearch.lessThan',
  });
  const formatter = (value: any) => `${value}${unit}`;
  const parser: any = (value: string) => value.replace(unit, '');

  return (
    <>
      <div className="number-search">
        <Radio className="radio" checked={isMoreThan} onChange={onChangeRadio}>
          {moreLabel}
        </Radio>
        <Radio className="radio" checked={!isMoreThan} onChange={onChangeRadio}>
          {lessLabel}
        </Radio>
        <InputNumber
          className="input"
          defaultValue={100}
          min={0}
          formatter={formatter}
          parser={parser}
          onChange={onChangeValue}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default NumberSearch;
