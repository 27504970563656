import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './PolicyInfo.styles';
import ListEditItem from '../../shared/ListEditItem';
import { openModal } from '../../../redux/store/ui/common/actions';
import { useIntl } from 'react-intl';
import { getStatusColor } from '../../../utils';
import { Tag } from 'antd';
import { renderDate } from '../../shared/TableLayout';
import { renderText } from '../../shared/DrawerLayout/renderText';
import { copyPrivacyPolicy } from '../../../redux/store/appData/privacyPolicy';

const PolicyInfo = ({ selectedPolicy }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onUpdateStatus = () => {
    dispatch(openModal('PolicyStatusModal'));
  };

  const onDuplicate = () => {
    dispatch(copyPrivacyPolicy(selectedPolicy.id));
  };

  const onAddPurpose = () => {
    dispatch(openModal('PurposeStatusModal'));
  };

  const renderGroup = (value) => {
    return (
      <Tag
        style={{
          borderColor: '#d3d3d3',
          backgroundColor: 'rgba(242, 242, 242, 0.6)',
          margin: 2,
        }}
        className="merchant-tag"
      >
        {value}
      </Tag>
    );
  };

  return (
    <>
      <div className="policy-info">
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.users.info.status`,
          })}
          leftItem={
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tag color={getStatusColor(selectedPolicy?.status)}>
                  {intl.formatMessage({
                    id: `fleetadmin.status.${selectedPolicy.status}`,
                  })}
                </Tag>
              </div>
            </>
          }
          onClickEdit={
            selectedPolicy.status === 'DRAFT' ? onUpdateStatus : undefined
          }
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.policy.info.lastmodified`,
          })}
          leftItem={renderDate(selectedPolicy.lastModified, true)}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.policy.info.lastmodifiedby`,
          })}
          leftItem={renderText(selectedPolicy.lastModifiedBy, true)}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.policy.info.brand`,
          })}
          leftItem={renderGroup(selectedPolicy.brandName)}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.policy.info.publishedat`,
          })}
          leftItem={renderDate(selectedPolicy.publishedFirstTimeAt, true)}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.policy.info.usersnr`,
          })}
          leftItem={renderText(null, true)}
        />

        <>
          {selectedPolicy?.status === 'PUBLISHED' && (
            <div className="blue-action" onClick={onDuplicate}>
              {intl.formatMessage({
                id: `fleetadmin.policy.info.duplicate`,
              })}
            </div>
          )}
          {selectedPolicy?.status === 'DRAFT' && (
            <div className="blue-action" onClick={onAddPurpose}>
              {intl.formatMessage({
                id: `fleetadmin.policy.add.purpose`,
              })}
            </div>
          )}
        </>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default PolicyInfo;
