import { request, requestAPI } from '../../../../utils/request';
import {
  circlesRequest,
  circlesSuccess,
  circlesFailure,
  setDisplayCircles,
  circleRequest,
  circleSuccess,
  circleFailure,
  addUserRequest,
  addUserSuccess,
  addUserFailure,
  removeUserRequest,
  removeUserSuccess,
  removeUserFailure,
  createCircleSuccess,
  createCircleFailure,
  createCircleRequest,
  updateCircleRequest,
  updateCircleSuccess,
  updateCircleFailure,
  addFleetRequest,
  addFleetSuccess,
  addFleetFailure,
  removeFleetRequest,
  removeFleetFailure,
  removeFleetSuccess,
  removeAdminRequest,
  removeAdminSuccess,
  removeAdminFailure,
  addAdminRequest,
  addAdminSuccess,
  addAdminFailure,
} from './actions';
import message from '../../../../utils/message';
import { CircleInputData, Dispatch, GetState } from '../../../../@types';
import { CirclesState } from './initialState';
import { localize } from '../../../helpers/localize';

export const fetchCircles = () => (dispatch: any, getState: any) => {
  const shouldFetchCircles = !getState()?.userData.circles?.loading;

  if (shouldFetchCircles) {
    dispatch(circlesRequest());
    return requestAPI('/fleets/circles')
      .then((circles) => {
        dispatch(circlesSuccess(circles));
        dispatch(setDisplayCircles(circles));
      })
      .catch((err) => {
        dispatch(circlesFailure(err));
        message.error(err.message);
      });
  } else {
    return Promise.resolve();
  }
};

export const fetchCircleById =
  (circleId: string) => (dispatch: any, getState: any) => {
    const circlesState = getState()?.userData.circles;
    const shouldFetchCircle = !circlesState?.loadingCircle;

    if (shouldFetchCircle) {
      dispatch(circleRequest());
      requestAPI(`/fleets/circles/${circleId}`)
        .then((circle) => {
          if (circle && circle?.ok === undefined) {
            dispatch(circleSuccess(circle));
          } else {
            dispatch(circleFailure({ name: 'Timout', message: 'Timout' }));
          }
        })
        .catch((err) => {
          dispatch(circleFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const addUserToCircle =
  (token: string, circleId: string, userEmail: string) =>
  (dispatch: Dispatch) => {
    dispatch(addUserRequest());
    requestAPI(
      `/fleets/circles/${circleId}/users/${userEmail}`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        dispatch(addUserSuccess());
        dispatch(fetchCircleById(circleId));
      })
      .catch((err) => {
        dispatch(addUserFailure(err));
        message.error(err.message);
      });
  };

export const addAdminToCircle =
  (circleId: string, userEmail: string) => (dispatch: Dispatch) => {
    dispatch(addAdminRequest());
    requestAPI(
      `/fleets/circles/${circleId}/admins/${userEmail}`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        dispatch(addAdminSuccess());
        dispatch(fetchCircleById(circleId));
      })
      .catch((err) => {
        dispatch(addAdminFailure(err));
        message.error(err.message);
      });
  };

export const removeUserFromCircle =
  (token: string, circleId: string, userId: string) => (dispatch: Dispatch) => {
    dispatch(removeUserRequest());
    requestAPI(
      `/fleets/circles/${circleId}/users/${userId}`,
      {},
      {
        method: 'DELETE',
      }
    )
      .then(() => {
        dispatch(removeUserSuccess());
        dispatch(fetchCircleById(circleId));
      })
      .catch((err) => {
        dispatch(removeUserFailure(err));
        message.error(err.message);
      });
  };

export const removeAdminFromCircle =
  (circleId: string, adminId: string) => (dispatch: Dispatch) => {
    dispatch(removeAdminRequest());
    requestAPI(
      `/fleets/circles/${circleId}/admins/${adminId}`,
      {},
      {
        method: 'DELETE',
      }
    )
      .then(() => {
        dispatch(removeAdminSuccess());
        dispatch(fetchCircleById(circleId));
      })
      .catch((err) => {
        dispatch(removeAdminFailure(err));
        message.error(err.message);
      });
  };

export const addFleetToCircle =
  (circleId: string, fleetId: string) => (dispatch: any) => {
    dispatch(addFleetRequest());
    requestAPI(
      `/fleets/circles/${circleId}/fleets/${fleetId}`,
      {},
      {
        method: 'PUT',
      }
    )
      .then((data) => {
        dispatch(addFleetSuccess(data));
      })
      .catch((err) => {
        dispatch(addFleetFailure(err));
        message.error(err.message);
      });
  };

export const removeFleetFromCircle =
  (circleId: string, fleetId: string) => (dispatch: any) => {
    dispatch(removeFleetRequest());
    requestAPI(
      `/fleets/circles/${circleId}/fleets/${fleetId}`,
      {},
      {
        method: 'DELETE',
      }
    )
      .then((data) => {
        dispatch(removeFleetSuccess(data));
      })
      .catch((err) => {
        dispatch(removeFleetFailure(err));
        message.error(err.message);
      });
  };

export const createCircle =
  (token: string, circleInputData: CircleInputData) =>
  (dispatch: Dispatch, getState) => {
    dispatch(createCircleRequest());
    requestAPI(
      `/fleets/circles`,
      {},
      {
        method: 'POST',
        body: JSON.stringify(circleInputData),
      }
    )
      .then((circle) => {
        message.success(localize('notification.created', getState));
        if (circle) {
          dispatch(createCircleSuccess(circle));
        }
        dispatch(fetchCircles());
      })
      .catch((err) => {
        dispatch(createCircleFailure(err));
        message.error(err.message);
      });
  };

const shouldCreateCircle = (state: CirclesState) => !state.creatingCircle;

export const createCircleIfNeeded = (
  token: string,
  circleInputData: CircleInputData
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldCreateCircle(getState().userData.circles)) {
      return dispatch(createCircle(token, circleInputData));
    } else {
      return Promise.resolve();
    }
  };
};

const updateCircle =
  (token: string, circleId: string, circleInputData: CircleInputData) =>
  (dispatch: Dispatch, getState: any) => {
    dispatch(updateCircleRequest());
    requestAPI(
      `/fleets/circles/${circleId}`,
      {},
      {
        method: 'PUT',
        body: JSON.stringify(circleInputData),
      }
    )
      .then((data) => {
        dispatch(updateCircleSuccess(data));
        message.success(localize('update.success', getState));
      })
      .catch((err) => {
        dispatch(updateCircleFailure(err));
        message.error(err.message);
      });
  };

const shouldUpdateCircle = (state: CirclesState) => !state.updatingCircle;

export const updateCircleIfNeeded = (
  token: string,
  circleId: string,
  circleInputData: CircleInputData
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldUpdateCircle(getState().userData.circles)) {
      return dispatch(updateCircle(token, circleId, circleInputData));
    } else {
      return Promise.resolve();
    }
  };
};
