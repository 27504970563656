import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import PolicyDrawerBody from './PolicyDrawerBody';
import { closePolicyDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectSelectedPrivacyPolicy } from '../../redux/store/appData/privacyPolicy';

const PolicyDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedPolicy = useSelector(selectSelectedPrivacyPolicy);
  const visible = selectedPolicy != null;

  const handleOnClose = () => {
    dispatch(closePolicyDrawerAndSetQueries());
  };

  return (
    selectedPolicy && (
      <DrawerLayout
        visible={visible}
        title={selectedPolicy?.id!!}
        body={<PolicyDrawerBody selectedPolicy={selectedPolicy} />}
        // nestedDrawers={
        //   <>{viewMode !== ViewMode.RECEIPTS && <ReceiptDrawer />}</>
        // }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default PolicyDrawer;
