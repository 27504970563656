import React, { useState } from 'react';
import styles from './PurposeDrawerBody.styles';
import TabLayout from '../../components/shared/TabLayout';
import { useIntl } from 'react-intl';
import { ProcessingPurposeData } from '../../@types';
import PurposeInfo from './PurposeInfoTab/PurposeInfo';

type Props = {
  selectedPurpose: ProcessingPurposeData;
};

const PurposeDrawerBody = ({ selectedPurpose }: Props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: (
        <span>
          {intl.formatMessage({
            id: `fleetadmin.users.tab.info`,
          })}
        </span>
      ),
      content: <PurposeInfo selectedPurpose={selectedPurpose} />,
    },
  ];

  return (
    <>
      <div className="purpose-drawer-body">
        <div className="purpose-name-wrapper">
          <p className="name">{selectedPurpose?.name}</p>
        </div>
        <TabLayout
          tabs={tabs}
          keyName="purpose-drawer"
          activeTab={activeTab}
          onChangeTab={(activeKey) => {
            setActiveTab(parseInt(activeKey.split('-').pop() || '0', 10));
          }}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default PurposeDrawerBody;
