const areaColumns = [
  { label: 'Area', value: 'name', disabled: true },
  { label: 'Fleet', value: 'fleetId', disabled: false },
  { label: 'No.Vehicles', value: 'id', disabled: false },
  { label: 'Created at', value: 'createdAt', disabled: false },
  { label: 'Last modified', value: 'lastUpdatedAt', disabled: false },
  { label: 'Location', value: 'location', disabled: false },
  { label: 'Action', value: 'operation', disabled: false },
];

export const defaultSelectedAreaColumns = areaColumns.map(
  (areaColumn) => areaColumn.value
);

export default areaColumns;
