import { ErrorResponse, FleetTripData } from '../../../../@types';

export interface CaresState {
  entities: FleetTripData[] | null;
  display: FleetTripData[] | null;
  selected: FleetTripData | null;
  error: ErrorResponse | null;
  loading: boolean;
  finishingTrip: boolean;
  finishTripViolations: string[];
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
  finishingTrip: false,
  finishTripViolations: [],
};
