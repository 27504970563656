import React from 'react';
import { useSelector } from 'react-redux';
import { ViewMode } from '../../hooks/useViewMode';
import styles from './NumOfResults.styles';
import { selectDisplayCares } from '../../redux/store/userData/cares';
import { selectDisplayAreas } from '../../redux/store/appData/areas';
import { selectDisplayCars } from '../../redux/store/userData/cars';
import { selectTotalClaims } from '../../redux/store/userData/claims';
import { selectTotalTrips } from '../../redux/store/userData/trips';
import { selectViewMode } from '../../redux/store/ui/common/selectors';
import { selectTotalReceipts } from '../../redux/store/userData/receipts/selectors';
import { selectTotalUsers } from '../../redux/store/userData/users';
import { selectDisplayCircles } from '../../redux/store/userData/circles';
import { selectDisplayBookings } from '../../redux/store/userData/trips/selectors';
import { selectDisplayAdmins } from '../../redux/store/userData/admins';
import { selectDisplayCategories } from '../../redux/store/userData/categories/selectors';
import { selectDisplayProfiles } from '../../redux/store/userData/profiles';
import { selectDisplayPricings } from '../../redux/store/appData/pricings/selectors';
import { selectDisplayPrivacyPolicy } from '../../redux/store/appData/privacyPolicy';

const selectorsMap = {
  [ViewMode.VEHICLE]: selectDisplayCars,
  [ViewMode.AREA]: selectDisplayAreas,
  [ViewMode.CLAIM]: selectTotalClaims,
  [ViewMode.CARE]: selectDisplayCares,
  [ViewMode.SHARE]: selectTotalTrips,
  [ViewMode.RECEIPTS]: selectTotalReceipts,
  [ViewMode.USERS]: selectTotalUsers,
  [ViewMode.CIRCLE]: selectDisplayCircles,
  [ViewMode.BOOKING]: selectDisplayBookings,
  [ViewMode.ADMINS]: selectDisplayAdmins,
  [ViewMode.CATEGORIES]: selectDisplayCategories,
  [ViewMode.PROFILE]: selectDisplayProfiles,
  [ViewMode.PRICING]: selectDisplayPricings,
  [ViewMode.PRIVACY_POLICY]: selectDisplayPrivacyPolicy,
};

const NumOfResults: React.FC = () => {
  const viewMode = useSelector(selectViewMode);
  const selectedItems = useSelector(selectorsMap[viewMode] || (() => null));
  let numOfItems = 0;
  if (Array.isArray(selectedItems)) {
    numOfItems = selectedItems.length;
  } else {
    numOfItems = selectedItems;
  }

  return (
    <>
      <div className="num-of-results-wrapper">
        <p className="num-of-results">{numOfItems} results</p>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default NumOfResults;
