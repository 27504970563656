export enum CategoriesTypes {
  CATEGORIES_REQUEST = 'userData/categories/CATEGORIES_REQUEST',
  CATEGORIES_SUCCESS = 'userData/categories/CATEGORIES_SUCCESS',
  CATEGORIES_FAILURE = 'userData/categories/CATEGORIES_FAILURE',
  CATEGORY_REQUEST = 'userData/categories/CATEGORY_REQUEST',
  CATEGORY_SUCCESS = 'userData/categories/CATEGORY_SUCCESS',
  CATEGORY_FAILURE = 'userData/categories/CATEGORY_FAILURE',
  SELECT_CATEGORY = 'userData/categories/SELECT_CATEGORY',
  SET_DISPLAY_CATEGORIES = 'userData/categories/SET_DISPLAY_CATEGORIES',
}
