import { requestAPI } from '../../../../utils/request';
import {
  archiveAreaFailure,
  archiveAreaRequest,
  archiveAreaSuccess,
  areaByIDFailure,
  areaByIDRequest,
  areaByIDSuccess,
  areasFailure,
  areasRequest,
  areasSuccess,
  createAreaFailure,
  createAreaRequest,
  createAreaSuccess,
  disableAreaFailure,
  disableAreaRequest,
  disableAreaSuccess,
  setDisplayAreas,
  uploadAreaFileFailure,
  uploadAreaFileRequest,
  uploadAreaFileSuccess,
} from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState, InputAreaData } from '../../../../@types';
import { AreaState } from './initialState';

export const fetchAreas = () => (dispatch: Dispatch) => {
  dispatch(areasRequest());
  requestAPI('/fleets/areas')
    .then((data) => {
      if (data.ok === undefined) {
        dispatch(areasSuccess(data));
        dispatch(setDisplayAreas(data));
      }
    })
    .catch((err) => {
      message.error(err.message);
      dispatch(areasFailure(err));
    });
};

const shouldFetchAreas = (areas: AreaState) =>
  !areas.entities && !areas.loading;

export const fetchAreasIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchAreas(getState().appData.areas)) {
      return dispatch(fetchAreas());
    } else {
      return Promise.resolve();
    }
  };
};

export const createArea =
  (token: string, inputData: InputAreaData) => (dispatch: Dispatch) => {
    dispatch(createAreaRequest());
    requestAPI(
      '/fleets/areas',
      {},
      { method: 'POST', body: JSON.stringify(inputData) }
    )
      .then((data) => {
        if (data) {
          dispatch(createAreaSuccess(data));
        }
        dispatch(fetchAreasIfNeeded);
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(createAreaFailure(err));
      });
  };

const shouldCreateArea = (areas: AreaState) => !areas.creatingArea;

export const createAreaIfNeeded = (
  cookieToken: string,
  inputData: InputAreaData
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldCreateArea(getState().appData.areas)) {
      return dispatch(
        createArea(getState()?.userData.user?.token || cookieToken, inputData)
      );
    } else {
      return Promise.resolve();
    }
  };
};

const disableArea = (token: string, areaId: string) => (dispatch: Dispatch) => {
  dispatch(disableAreaRequest());
  const body = new FormData();
  body.append('state', 'deleted');
  requestAPI(`/fleets/areas/${areaId}`, {}, { method: 'PUT', body })
    .then((data) => {
      message.success('Archived');
      dispatch(disableAreaSuccess(data));
    })
    .catch((err) => {
      dispatch(disableAreaFailure(err));
      message.error(err.message);
    });
};

export const uploadAreaFile =
  (token: string, areaId: string, file: any) => (dispatch: Dispatch) => {
    dispatch(uploadAreaFileRequest());
    const formData = new FormData();
    formData.append('file', file);
    requestAPI(
      `/fleets/areas/${areaId}`,
      {},
      {
        method: 'PUT',
        body: formData,
      }
    )
      .then((data) => {
        message.success('Uploaded!');
        dispatch(uploadAreaFileSuccess(data));
      })
      .catch((err) => {
        dispatch(uploadAreaFileFailure(err));
        message.error(err.message);
      });
  };

const shouldUploadAreaFile = (state: AreaState) => !state.uploadingFile;

export const uploadAreaFileIfNeeded = (
  token: string,
  areaId: string,
  file: any
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldUploadAreaFile(getState().appData.areas)) {
      return dispatch(uploadAreaFile(token, areaId, file));
    } else {
      return Promise.resolve();
    }
  };
};

export const archiveArea = () => (dispatch: any, getState: any) => {
  const { selected } = getState()?.appData.areas;
  dispatch(archiveAreaRequest());
  requestAPI(`/fleets/areas/${selected.id}`, {}, { method: 'DELETE' })
    .then((area) => {
      dispatch(archiveAreaSuccess(area));
      message.success('Area archived');
    })
    .catch((err) => {
      dispatch(archiveAreaFailure(err));
      message.error(err.message);
    });
};

export const fetchAreaById =
  (areaId: string | null) => (dispatch: Dispatch) => {
    dispatch(areaByIDRequest());
    requestAPI(`/fleets/areas/${areaId}`)
      .then((area) => {
        if (area.ok === undefined) dispatch(areaByIDSuccess(area));
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(areaByIDFailure(err));
      });
  };
