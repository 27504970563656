/* eslint-disable react-hooks/rules-of-hooks */
import { ViewMode } from './useViewMode';
import { useSelector } from 'react-redux';
import { selectSelectedArea } from '../redux/store/appData/areas';
import { selectSelectedCategory } from '../redux/store/userData/categories';
import { selectSelectedPricing } from '../redux/store/appData/pricings';
import { selectSelectedReceipt } from '../redux/store/userData/receipts';
import { selectSelectedProfile } from '../redux/store/userData/profiles';
import { selectSelectedCare } from '../redux/store/userData/cares';
import {
  selectSelectedBooking,
  selectSelectedTrip,
} from '../redux/store/userData/trips';
import { selectSelectedClaim } from '../redux/store/userData/claims';
import { selectSelectedCircle } from '../redux/store/userData/circles';
import { selectSelectedUser } from '../redux/store/userData/users';
import { selectSelectedAdmin } from '../redux/store/userData/admins';
import { selectSelectedCar } from '../redux/store/userData/cars';
import { selectSelectedPrivacyPolicy } from '../redux/store/appData/privacyPolicy';

export const useSelectedDrawer = (viewMode) => {
  switch (viewMode) {
    case ViewMode.VEHICLE:
      return useSelector(selectSelectedCar);
    case ViewMode.AREA:
      return useSelector(selectSelectedArea);
    case ViewMode.CATEGORIES:
      return useSelector(selectSelectedCategory);
    case ViewMode.PRICING:
      return useSelector(selectSelectedPricing);
    case ViewMode.PRIVACY_POLICY:
      return useSelector(selectSelectedPrivacyPolicy);
    case ViewMode.RECEIPTS:
      return useSelector(selectSelectedReceipt);
    case ViewMode.PROFILE:
      return useSelector(selectSelectedProfile);
    case ViewMode.CARE:
      return useSelector(selectSelectedCare);
    case ViewMode.SHARE:
      return useSelector(selectSelectedTrip);
    case ViewMode.BOOKING:
      return useSelector(selectSelectedBooking);
    case ViewMode.CLAIM:
      return useSelector(selectSelectedClaim);
    case ViewMode.CIRCLE:
      return useSelector(selectSelectedCircle);
    case ViewMode.USERS:
      return useSelector(selectSelectedUser);
    case ViewMode.ADMINS:
      return useSelector(selectSelectedAdmin);
    default:
      return [];
  }
};
