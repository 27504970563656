import { Reducer } from '../../../../@types';
import { AdminsTypes, PricingTypes } from '../../../../@types/enums';
import { initialState, PricingsState } from './initialState';

// @ts-ignore
export const pricingsReducer: Reducer<PricingsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case PricingTypes.PRICINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PricingTypes.PRICINGS_SUCCESS:
      return {
        ...state,
        entities: action.payload.map((item) => ({
          ...item,
          versionId: `${item?.id}-${item?.version}`,
        })),
        loading: false,
      };
    case PricingTypes.PRICINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case PricingTypes.PRICING_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state?.display
          ? [
              ...state.display.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
      };
    case PricingTypes.BRAND_PRICING_REQUEST:
      return {
        ...state,
        loadingBrandPricings: true,
      };
    case PricingTypes.BRAND_PRICING_SUCCESS:
      return {
        ...state,
        brandPricings: {
          ...state.brandPricings,
          [action.payload.brandId]: action.payload.pricings,
        },
        loadingBrandPricings: false,
      };
    case PricingTypes.BRAND_PRICING_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingBrandPricings: false,
      };
    case PricingTypes.SELECT_PRICING:
      return {
        ...state,
        selected: action.payload,
      };
    case PricingTypes.SET_DISPLAY_PRICING:
      return {
        ...state,
        display: action.payload.map((item) => ({
          ...item,
          versionId: `${item?.id}-${item?.version}`,
        })),
      };
    case PricingTypes.UPLOAD_PRICING_FILE_REQUEST:
      return {
        ...state,
        uploadingFile: true,
      };
    case PricingTypes.UPLOAD_PRICING_FILE_SUCCESS:
      return {
        ...state,
        entities: state?.entities?.map((pricing) => {
          if (pricing.id === `${action.payload.id}-${action.payload.version}`) {
            return action.payload;
          }
          return pricing;
        }),
        display: state?.display?.map((pricing) => {
          if (pricing.id === `${action.payload.id}-${action.payload.version}`) {
            return action.payload;
          }
          return pricing;
        }),
        selected: action.payload,
        uploadingFile: false,
      };
    case PricingTypes.PRICING_UPDATE_SUCCESS: {
      const newPricing = {
        ...action.payload,
        id: `${action.payload.id}-${action.payload.version}`,
      };
      const isPricingVersionBumped = !state?.entities?.find(
        (pricing) =>
          pricing.id === newPricing.id &&
          // @ts-ignore
          pricing?.version === newPricing?.version
      );
      return {
        ...state,
        entities: isPricingVersionBumped
          ? [newPricing, ...state.entities]
          : state?.entities?.map((pricing) => {
              if (pricing.id === newPricing.id) {
                return newPricing;
              }
              return pricing;
            }),
        display: isPricingVersionBumped
          ? [newPricing, ...state.display]
          : state?.display?.map((pricing) => {
              if (
                pricing.id === newPricing.id &&
                // @ts-ignore
                pricing?.version === newPricing?.version
              ) {
                return newPricing;
              }
              return pricing;
            }),
        selected: newPricing,
        uploadingFile: false,
      };
    }
    case PricingTypes.PRICING_DELETE_SUCCESS:
      return {
        ...state,
        entities: state?.entities?.filter(
          (pricing) => !(pricing.id === action.payload.pricingId)
        ),
        display: state?.display?.filter(
          (pricing) => !(pricing.id === action.payload.pricingId)
        ),
        selected: null,
        loading: false,
      };
    case PricingTypes.UPLOAD_PRICING_FILE_FAILURE:
      return {
        ...state,
        uploadingFile: false,
      };
    default:
      return state;
  }
};
