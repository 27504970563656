export enum CaresTypes {
  CARES_REQUEST = 'userData/cares/CARES_REQUEST',
  CARES_SUCCESS = 'userData/cares/CARES_SUCCESS',
  CARES_FAILURE = 'userData/cares/CARES_FAILURE',
  CARE_REQUEST = 'userData/cares/CARE_REQUEST',
  CARE_SUCCESS = 'userData/cares/CARE_SUCCESS',
  CARE_FAILURE = 'userData/cares/CARE_FAILURE',
  SET_DISPLAY_CARES = 'userData/cares/SET_DISPLAY_CARES',
  SELECT_CARE = 'userData/cares/SELECT_CARE',
  FINISH_SERVICE_TRIP_REQUEST = 'userData/cares/FINISH_SERVICE_TRIP_REQUEST',
  FINISH_SERVICE_TRIP_SUCCESS = 'userData/cares/FINISH_SERVICE_TRIP_SUCCESS',
  FINISH_SERVICE_TRIP_FAILURE = 'userData/cares/FINISH_SERVICE_TRIP_FAILURE',
  FINISH_SERVICE_TRIP_VIOLATIONS = 'userData/cares/FINISH_SERVICE_TRIP_VIOLATIONS',
}
