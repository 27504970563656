/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux';
import { selectDisplayAreas } from '../redux/store/appData/areas';
import { selectDisplayPricings } from '../redux/store/appData/pricings';
import { selectDisplayCares } from '../redux/store/userData/cares';
import { selectDisplayCars } from '../redux/store/userData/cars';
import { selectDisplayCircles } from '../redux/store/userData/circles';
import { selectDisplayClaims } from '../redux/store/userData/claims';
import { selectDisplayProfiles } from '../redux/store/userData/profiles';
import {
  selectDisplayBookings,
  selectDisplayTrips,
} from '../redux/store/userData/trips';
import { ViewMode } from './useViewMode';
import { selectDisplayUsers } from '../redux/store/userData/users';
import { selectDisplayCategories } from '../redux/store/userData/categories';
import { selectDisplayAdmins } from '../redux/store/userData/admins';
import { selectDisplayReceipts } from '../redux/store/userData/receipts';
import {
  selectDisplayColumnsForAdmins,
  selectDisplayColumnsForArea,
  selectDisplayColumnsForBooking,
  selectDisplayColumnsForCar,
  selectDisplayColumnsForCare,
  selectDisplayColumnsForCategory,
  selectDisplayColumnsForCircle,
  selectDisplayColumnsForClaim,
  selectDisplayColumnsForPricing,
  selectDisplayColumnsForPrivacyPolicy,
  selectDisplayColumnsForProfile,
  selectDisplayColumnsForReceipts,
  selectDisplayColumnsForTrip,
  selectDisplayColumnsForUsers,
} from '../redux/store/ui/common';
import { selectDisplayPrivacyPolicy } from '../redux/store/appData/privacyPolicy';

export const useDisplayViewMode = (viewMode) => {
  switch (viewMode) {
    case ViewMode.VEHICLE:
      return useSelector(selectDisplayCars);
    case ViewMode.AREA:
      return useSelector(selectDisplayAreas);
    case ViewMode.CLAIM:
      return useSelector(selectDisplayClaims);
    case ViewMode.CARE:
      return useSelector(selectDisplayCares);
    case ViewMode.SHARE:
      return useSelector(selectDisplayTrips);
    case ViewMode.BOOKING:
      return useSelector(selectDisplayBookings);
    case ViewMode.CIRCLE:
      return useSelector(selectDisplayCircles);
    case ViewMode.PRICING:
      return useSelector(selectDisplayPricings);
    case ViewMode.PRIVACY_POLICY:
      return useSelector(selectDisplayPrivacyPolicy);
    case ViewMode.PROFILE:
      return useSelector(selectDisplayProfiles);
    case ViewMode.USERS:
      return useSelector(selectDisplayUsers);
    case ViewMode.CATEGORIES:
      return useSelector(selectDisplayCategories);
    case ViewMode.ADMINS:
      return useSelector(selectDisplayAdmins);
    case ViewMode.RECEIPTS:
      return useSelector(selectDisplayReceipts);
    default:
      return null;
  }
};

export const useDisplayColumns = (viewMode) => {
  switch (viewMode) {
    case ViewMode.VEHICLE:
      return useSelector(selectDisplayColumnsForCar);
    case ViewMode.AREA:
      return useSelector(selectDisplayColumnsForArea);
    case ViewMode.CATEGORIES:
      return useSelector(selectDisplayColumnsForCategory);
    case ViewMode.PRICING:
      return useSelector(selectDisplayColumnsForPricing);
    case ViewMode.PRIVACY_POLICY:
      return useSelector(selectDisplayColumnsForPrivacyPolicy);
    case ViewMode.RECEIPTS:
      return useSelector(selectDisplayColumnsForReceipts);
    case ViewMode.PROFILE:
      return useSelector(selectDisplayColumnsForProfile);
    case ViewMode.CARE:
      return useSelector(selectDisplayColumnsForCare);
    case ViewMode.SHARE:
      return useSelector(selectDisplayColumnsForTrip);
    case ViewMode.BOOKING:
      return useSelector(selectDisplayColumnsForBooking);
    case ViewMode.CLAIM:
      return useSelector(selectDisplayColumnsForClaim);
    case ViewMode.CIRCLE:
      return useSelector(selectDisplayColumnsForCircle);
    case ViewMode.USERS:
      return useSelector(selectDisplayColumnsForUsers);
    case ViewMode.ADMINS:
      return useSelector(selectDisplayColumnsForAdmins);
    default:
      return [];
  }
};
