export enum AreasTypes {
  AREAS_REQUEST = 'appData/areas/AREAS_REQUEST',
  AREAS_SUCCESS = 'appData/areas/AREAS_SUCCESS',
  AREAS_FAILURE = 'appData/areas/AREAS_FAILURE',
  SELECT_AREA = 'appData/areas/SELECT_AREA',
  AREA_BY_ID_REQUEST = 'appData/areas/AREAS_BY_ID_REQUEST',
  AREA_BY_ID_SUCCESS = 'appData/areas/AREAS_BY_ID_SUCCESS',
  AREA_BY_ID_FAILURE = 'appData/areas/AREAS_BY_ID_FAILURE',
  SET_DISPLAY_AREAS = 'appData/areas/SET_DISPLAY_AREAS',
  DISABLE_AREA_REQUEST = 'appData/areas/DISABLE_AREA_REQUEST',
  DISABLE_AREA_SUCCESS = 'appData/areas/DISABLE_AREA_SUCCESS',
  DISABLE_AREA_FAILURE = 'appData/areas/DISABLE_AREA_FAILURE',
  UPLOAD_AREA_FILE_REQUEST = 'appData/areas/UPLOAD_AREA_FILE_REQUEST',
  UPLOAD_AREA_FILE_SUCCESS = 'appData/areas/UPLOAD_AREA_FILE_SUCCESS',
  UPLOAD_AREA_FILE_FAILURE = 'appData/areas/UPLOAD_AREA_FILE_FAILURE',
  CREATE_AREA_REQUEST = 'appData/areas/CREATE_AREA_REQUEST',
  CREATE_AREA_SUCCESS = 'appData/areas/CREATE_AREA_SUCCESS',
  CREATE_AREA_FAILURE = 'appData/areas/CREATE_AREA_FAILURE',
  ADD_HIDE_ON_MAP = 'appData/areas/ADD_HIDE_ON_MAP',
  REMOVE_HIDE_ON_MAP = 'appData/areas/REMOVE_HIDE_ON_MAP',
  ARCHIVE_AREA_REQUEST = 'appData/areas/ARCHIVE_AREA_REQUEST',
  ARCHIVE_AREA_SUCCESS = 'appData/areas/ARCHIVE_AREA_SUCCESS',
  ARCHIVE_AREA_FAILURE = 'appData/areas/ARCHIVE_AREA_FAILURE',
}
