import { FleetTripData } from '../../../@types';
import serviceTypes from '../../../assets/serviceTypes';

export const optionsForServiceStatus: Array<FleetTripData['status']> = [
  'PLANNED',
  'OPEN',
  'IN_PROGRESS',
  'CLOSED',
];

export const optionsForServiceType: any = serviceTypes;
