const privacyPolicyColumns = [
  { label: 'Name', value: 'name', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Brand', value: 'brandName', disabled: false },
  { label: 'Last modified at', value: 'lastModified', disabled: false },
  { label: 'Published at', value: 'publishedFirstTimeAt', disabled: false },
];

export const defaultSelectedPrivacyPolicyColumns = privacyPolicyColumns.map(
  (column) => column.value
);

export default privacyPolicyColumns;
