import React from 'react';
import LinkToVehicle from '../../components/shared/LinkToVehicle';
import styles from './VINAndPlate.styles';

const VINAndPlate = ({ chassisNumber, plate, carId = '' }: any) => {
  return (
    <>
      <div className="wrapper">
        {chassisNumber && (
          <div className="vin">
            <p className="label">VIN</p>
            <p className="value">
              <LinkToVehicle carId={carId} text={chassisNumber} />
            </p>
          </div>
        )}
        <div className="plate">
          <p className="label">Plate</p>
          <p className="value">
            <LinkToVehicle carId={carId} text={plate} />
          </p>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default VINAndPlate;
