import { Reducer } from '../../../../@types';
import { CirclesTypes } from '../../../../@types/enums';
import { initialState, CirclesState } from './initialState';

// @ts-ignore
export const circlesReducer: Reducer<CirclesState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case CirclesTypes.CIRCLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CirclesTypes.CIRCLES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        fetchedCircles: null,
        loading: false,
      };
    case CirclesTypes.CIRCLES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CirclesTypes.CIRCLE_REQUEST:
      return {
        ...state,
        loadingCircle: true,
      };
    case CirclesTypes.CIRCLE_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities!.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state.display
          ? [
              ...state.display!.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
        fetchedCircles: state.fetchedCircles?.includes(action.payload.id)
          ? state.fetchedCircles
          : state.fetchedCircles === null
          ? [action.payload.id]
          : [...state.fetchedCircles, action.payload.id],
        loadingCircle: false,
      };
    case CirclesTypes.CIRCLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingCircle: false,
      };
    case CirclesTypes.UPDATE_CIRCLE_REQUEST:
      return {
        ...state,
        updatingCircle: true,
      };
    case CirclesTypes.UPDATE_CIRCLE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        fetchedCircles: state.fetchedCircles?.includes(action.payload.id)
          ? state.fetchedCircles
          : state.fetchedCircles === null
          ? [action.payload.id]
          : [...state.fetchedCircles, action.payload.id],
        updatingCircle: false,
      };
    case CirclesTypes.UPDATE_CIRCLE_FAILURE:
      return {
        ...state,
        error: action.error,
        updatingCircle: false,
      };
    case CirclesTypes.ADD_USER_REQUEST:
      return {
        ...state,
        addingUser: true,
      };
    case CirclesTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        addingUser: false,
      };
    case CirclesTypes.ADD_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        addingUser: false,
      };
    case CirclesTypes.REMOVE_USER_REQUEST:
      return {
        ...state,
        removingUser: true,
      };
    case CirclesTypes.REMOVE_USER_SUCCESS:
      return {
        ...state,
        removingUser: false,
      };
    case CirclesTypes.REMOVE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        removingUser: false,
      };
    case CirclesTypes.ADD_FLEET_REQUEST:
      return {
        ...state,
        addingFleet: true,
      };
    case CirclesTypes.ADD_FLEET_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        addingFleet: false,
      };
    case CirclesTypes.ADD_FLEET_FAILURE:
      return {
        ...state,
        error: action.error,
        addingFleet: false,
      };
    case CirclesTypes.REMOVE_FLEET_REQUEST:
      return {
        ...state,
        removingFleet: true,
      };
    case CirclesTypes.REMOVE_FLEET_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        removingFleet: false,
      };
    case CirclesTypes.REMOVE_FLEET_FAILURE:
      return {
        ...state,
        error: action.error,
        removingFleet: false,
      };
    case CirclesTypes.CREATE_CIRCLE_REQUEST:
      return {
        ...state,
        creatingCircle: true,
      };
    case CirclesTypes.CREATE_CIRCLE_SUCCESS:
      return {
        ...state,
        selected: action.payload,
        creatingCircle: false,
      };
    case CirclesTypes.CREATE_CIRCLE_FAILURE:
      return {
        ...state,
        error: action.error,
        creatingCircle: false,
      };
    case CirclesTypes.SELECT_CIRCLE:
      return {
        ...state,
        selected: action.payload,
      };
    case CirclesTypes.SET_DISPLAY_CIRCLES:
      return {
        ...state,
        display: action.payload,
      };
    default:
      return state;
  }
};
