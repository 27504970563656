export enum UsersTypes {
  USERS_REQUEST = 'userData/Users/USERS_REQUEST',
  USERS_SUCCESS = 'userData/Users/USERS_SUCCESS',
  USERS_FAILURE = 'userData/Users/USERS_FAILURE',
  SET_DISPLAY_USERS = 'userData/Users/SET_DISPLAY_USERS',
  SELECT_USER = 'userData/Users/SELECT_USER',
  USERS_UPDATE_REQUEST = 'userData/Users/USERS_UPDATE_REQUEST',
  USERS_UPDATE_SUCCESS = 'userData/Users/USERS_UPDATE_SUCCESS',
  USERS_UPDATE_FAILURE = 'userData/Users/USERS_UPDATE_FAILURE',
  USER_DUPLICATES_REQUEST = 'userData/Users/USER_DUPLICATES_REQUEST',
  USER_DUPLICATES_SUCCESS = 'userData/Users/USER_DUPLICATES_SUCCESS',
  USER_DUPLICATES_FAILURE = 'userData/Users/USER_DUPLICATES_FAILURE',
  USER_TRANSACTION_REQUEST = 'userData/Users/USER_TRANSACTION_REQUEST',
  USER_TRANSACTION_SUCCESS = 'userData/Users/USER_TRANSACTION_SUCCESS',
  USER_TRANSACTION_FAILURE = 'userData/Users/USER_TRANSACTION_FAILURE',
  UPLOAD_REQUEST = 'userData/Users/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = 'userData/Users/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = 'userData/Users/UPLOAD_FAILURE',
  PAYMENT_DELETE_REQUEST = 'userData/Users/PAYMENT_DELETE_REQUEST',
  PAYMENT_DELETE_SUCCESS = 'userData/Users/PAYMENT_DELETE_SUCCESS',
  PAYMENT_DELETE_FAILURE = 'userData/Users/PAYMENT_DELETE_FAILURE',
  SET_USER_PAYMENT_ID = 'userData/Users/SET_USER_PAYMENT_ID',
  UPLOAD_DL_REQUEST = 'userData/Users/UPLOAD_DL_REQUEST',
  USERS_ARCHIVE_SUCCESS = 'userData/Users/USERS_ARCHIVE_SUCCESS',
  USERS_PDF_REQUEST = 'userData/Users/USERS_PDF_REQUEST',
  USERS_PDF_SUCCESS = 'userData/Users/USERS_PDF_SUCCESS',
  USERS_PDF_FAILURE = 'userData/Users/USERS_PDF_FAILURE',
  DOWNLOAD_GDPR_REQUEST = 'userData/Users/DOWNLOAD_GDPR_REQUEST',
  DOWNLOAD_GDPR_SUCCESS = 'userData/Users/DOWNLOAD_GDPR_SUCCESS',
  DOWNLOAD_GDPR_FAILURE = 'userData/Users/DOWNLOAD_GDPR_FAILURE',
  SYNC_REQUEST = 'userData/Users/SYNC_REQUEST',
  SYNC_SUCCESS = 'userData/Users/SYNC_SUCCESS',
  SYNC_FAILURE = 'userData/Users/SYNC_FAILURE',
  SET_TOTAL_USERS = 'userData/Users//SET_TOTAL_USERS',
  CANCEL_DEPOSIT_SUCCESS = 'userData/Users/CANCEL_DEPOSIT_SUCCESS',
  CANCEL_DEPOSIT_FAILURE = 'userData/Users/CANCEL_DEPOSIT_FAILURE',
  USER_DUPLICATE_SUCCESS = 'userData/Users/USER_DUPLICATE_SUCCESS',
  TARIFFS_REQUEST = 'userData/Users/TARIFFS_REQUEST',
  TARIFFS_SUCCESS = 'userData/Users/TARIFFS_SUCCESS',
  TARIFFS_FAILURE = 'userData/Users/TARIFFS_FAILURE',
}
