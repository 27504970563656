import React, { useState } from 'react';
import styles from './PolicyDrawerBody.styles';
import TabLayout from '../../components/shared/TabLayout';
import { useIntl } from 'react-intl';
import { PrivacyPolicyData } from '../../@types';
import PolicyInfo from './PolicyInfoTab/PolicyInfo';
type Props = {
  selectedPolicy: PrivacyPolicyData;
};
const PolicyDrawerBody = ({ selectedPolicy }: Props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: (
        <span>
          {intl.formatMessage({
            id: `fleetadmin.users.tab.info`,
          })}
        </span>
      ),
      content: <PolicyInfo selectedPolicy={selectedPolicy} />,
    },
  ];

  return (
    <>
      <div className="policy-drawer-body">
        <div className="policy-name-wrapper">
          <p className="name">{selectedPolicy?.name}</p>
        </div>
        <TabLayout
          tabs={tabs}
          keyName="policy-drawer"
          activeTab={activeTab}
          onChangeTab={(activeKey) => {
            setActiveTab(parseInt(activeKey.split('-').pop() || '0', 10));
          }}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default PolicyDrawerBody;
