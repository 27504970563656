import targetsForArea from '../assets/targetsForArea';
import targetsForCare from '../assets/targetsForCare';
import targetsForClaim from '../assets/targetsForClaim';
import targetsForVehicle from '../assets/targetsForVehicle';
import targetsForShare from '../../../components/Filter/assets/targetsForShare';
import targetsForBooking from '../../../components/Filter/assets/targetsForBooking';
import targetsForCategory from '../../../components/Filter/assets/targetsForCategory';
import targetsForCircle from '../../../components/Filter/assets/targetsForCircle';
import targetsForPricing from '../../../components/Filter/assets/targetsForPricing';
import targetsForProfile from '../../../components/Filter/assets/targetsForProfile';
import targetsForUsers from '../assets/targetsForUsers';
import targetsForReceipts from '../assets/targetsForReceipts';
import targetsForPrivacy from '../assets/targetsForPrivacy';

const getKeysWithTypeOf = (type: string) => {
  const targets = [
    ...targetsForArea,
    ...targetsForCare,
    ...targetsForClaim,
    ...targetsForShare,
    ...targetsForBooking,
    ...targetsForVehicle,
    ...targetsForProfile,
    ...targetsForCategory,
    ...targetsForPricing,
    ...targetsForCircle,
    ...targetsForUsers,
    ...targetsForReceipts,
    ...targetsForPrivacy,
  ];

  return targets
    .filter((target) => target.type === type)
    .map((target) => target.value);
};

export default getKeysWithTypeOf;
