import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, FleetAdminCircleData } from '../../../../@types';
import { UsersTypes } from '../../../../@types/enums/redux/store';

export const usersRequest = () => createAction(UsersTypes.USERS_REQUEST);

export const usersSuccess = (payload: FleetAdminCircleData[]) =>
  createAction(UsersTypes.USERS_SUCCESS, payload);

export const usersFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.USERS_FAILURE, error);

export const setDisplayUsers = (payload: any[]) =>
  createAction(UsersTypes.SET_DISPLAY_USERS, payload);

export const setTotalUsers = (payload: number) =>
  createAction(UsersTypes.SET_TOTAL_USERS, payload);

export const selectUser = (payload: any | null) =>
  createAction(UsersTypes.SELECT_USER, payload);

export const setUserPaymentId = (payload: string) =>
  createAction(UsersTypes.SET_USER_PAYMENT_ID, payload);

export const usersUpdateRequest = () =>
  createAction(UsersTypes.USERS_UPDATE_REQUEST);

export const userUpdateSuccess = (payload: any) =>
  createAction(UsersTypes.USERS_UPDATE_SUCCESS, payload);

export const userDuplicateSuccess = (payload: any) =>
  createAction(UsersTypes.USER_DUPLICATE_SUCCESS, payload);

export const usersUpdateFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.USERS_UPDATE_FAILURE, error);

export const archiveSuccess = (payload: any) =>
  createAction(UsersTypes.USERS_ARCHIVE_SUCCESS, payload);

export const userDuplicatesRequest = () =>
  createAction(UsersTypes.USER_DUPLICATES_REQUEST);

export const userDuplicatesSuccess = (payload: any) =>
  createAction(UsersTypes.USER_DUPLICATES_SUCCESS, payload);

export const userDuplicatesFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.USER_DUPLICATES_FAILURE, error);

export const userTransactionsRequest = () =>
  createAction(UsersTypes.USER_TRANSACTION_REQUEST);

export const userTransactionsSuccess = (payload: any) =>
  createAction(UsersTypes.USER_TRANSACTION_SUCCESS, payload);

export const userTransactionsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.USER_TRANSACTION_FAILURE, error);

export const uploadRequest = () => createAction(UsersTypes.UPLOAD_REQUEST);

export const uploadSuccess = () => createAction(UsersTypes.UPLOAD_SUCCESS);

export const uploadFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.UPLOAD_FAILURE, error);

export const tariffsRequest = () => createAction(UsersTypes.TARIFFS_REQUEST);

export const tariffsSuccess = (payload: any) =>
  createAction(UsersTypes.TARIFFS_SUCCESS, payload);

export const tariffsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.TARIFFS_FAILURE, error);

export const paymentDeleteRequest = () =>
  createAction(UsersTypes.PAYMENT_DELETE_REQUEST);

export const paymentDeleteSuccess = (payload: any) =>
  createAction(UsersTypes.PAYMENT_DELETE_SUCCESS, payload);

export const paymentDeleteFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.PAYMENT_DELETE_FAILURE, error);

export const downloadGDPRRequest = () =>
  createAction(UsersTypes.DOWNLOAD_GDPR_REQUEST);

export const downloadGDPRSuccess = () =>
  createAction(UsersTypes.DOWNLOAD_GDPR_SUCCESS);

export const downloadGDPRFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.DOWNLOAD_GDPR_FAILURE, error);

export const syncRequest = () => createAction(UsersTypes.SYNC_REQUEST);

export const syncSuccess = (payload: any) =>
  createAction(UsersTypes.SYNC_SUCCESS, payload);

export const syncFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.SYNC_FAILURE, error);

export const cancelDepositSuccess = (payload: any) =>
  createAction(UsersTypes.CANCEL_DEPOSIT_SUCCESS, payload);

export const cancelDepositFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.CANCEL_DEPOSIT_FAILURE, error);

export const uploadDLImageRequest = () =>
  createAction(UsersTypes.UPLOAD_DL_REQUEST);

export const generatePDFRequest = () =>
  createAction(UsersTypes.USERS_PDF_REQUEST);

export const generatePDFSuccess = () =>
  createAction(UsersTypes.USERS_PDF_SUCCESS);

export const generatePDFFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UsersTypes.USERS_PDF_FAILURE, error);
