import React, { useState, useEffect } from 'react';
import { Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import getBrands from '../../utils/getBrands';
import getAreaNameById from '../../utils/getAreaNameById';
import getFleetNameById from '../../utils/getFleetNameById';
import getBrandCodeById from '../../utils/getBrandCodeById';
import getCirclesNameById from '../../utils/getCircleNameById';
import booleanToText from '../../utils/booleanToText';
import styles from './AppliedFiltersTag.styles';
import { selectAreas } from '../../redux/store/appData/areas';
import { selectCares } from '../../redux/store/userData/cares';
import { selectCircles } from '../../redux/store/userData/circles';
import { selectFleets } from '../../redux/store/appData/fleets';
import { removeAndApplyFilter } from '../../redux/store/ui/filters';
import { getBrandNameById } from '../ProfilesTable/getColumns';
import { useDisplayViewMode } from '../../hooks/useDisplayViewMode';
import getKeysWithTypeOf from './helpers/getKeysWithTypeOf';
import { ViewMode } from '../../hooks/useViewMode';
import { useLoadingViewMode } from '../../hooks/useLoadingViewMode';

const REMOVE_ICON = '/images/filter/remove.svg';

const booleanTypeTargets = getKeysWithTypeOf('boolean');

type AppliedFiltersTagProps = {
  viewMode: string;
  label: string;
  value: any;
};

const AppliedFiltersTag = ({
  viewMode,
  label,
  value,
}: AppliedFiltersTagProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const loading = useLoadingViewMode(viewMode);
  const areas = useSelector(selectAreas);
  const fleets = useSelector(selectFleets);
  const cares = useSelector(selectCares);
  const circles = useSelector(selectCircles);
  const brands = getBrands(cares);
  const [count, setCount] = useState(0);
  const filteredArray = useDisplayViewMode(viewMode);

  const handleOnClickRemove =
    ({ key, value }) =>
    (e) => {
      e.preventDefault();
      dispatch(removeAndApplyFilter({ viewMode, key, value }));
    };

  const valueMapper = {
    areaId: () => getAreaNameById(areas, value),
    fleetId: () => getFleetNameById(fleets, value),
    brandId: () => getBrandNameById(fleets, value),
    brand: () => getBrandCodeById(brands, value),
    circles: () => getCirclesNameById(circles, value),
    serviceTypes: () =>
      intl.formatMessage({ id: `fleetadmin.serviceType.${value}` }),
    blockReasons: () =>
      intl.formatMessage({ id: `fleetadmin.blockReasons.${value}` }),
    status: () => {
      if (viewMode === ViewMode.VEHICLE) {
        return intl.formatMessage({ id: `fleetadmin.carStatus.${value}` });
      } else if (viewMode === ViewMode.CLAIM) {
        return intl.formatMessage({
          id: `fleetadmin.claimStatus.tag.${value}`,
        });
      } else if (viewMode === ViewMode.CARE) {
        return intl.formatMessage({ id: `fleetadmin.serviceStatus.${value}` });
      } else if (
        viewMode === ViewMode.SHARE ||
        viewMode === ViewMode.PRIVACY_POLICY
      ) {
        return intl.formatMessage({ id: `fleetadmin.status.${value}` });
      } else {
        return value;
      }
    },
    default: () =>
      booleanTypeTargets.includes(label) ? booleanToText(value) : value,
  };

  useEffect(() => {
    const displayEntity = filteredArray?.filter(
      (item) => item[label] === value
    );
    setCount(displayEntity?.length || 0);
  }, [filteredArray, label, value]);

  return !loading ? (
    <div className="container">
      <div className="badge">
        <Badge
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            backgroundColor: '#A2AD00',
          }}
          count={count}
          overflowCount={999}
        />
      </div>
      <p className="applied-filter">
        <span className="target">
          {intl.formatMessage({
            id: `fleetadmin.filter.${viewMode}.${label}`,
          })}
        </span>
        <span className="separator">:</span>
        <span className="value">
          {(valueMapper[label] || valueMapper.default)()}
        </span>
        <span
          className="remove"
          onClick={handleOnClickRemove({ key: label, value })}
        >
          <img src={REMOVE_ICON} alt="x" />
        </span>
      </p>
      <style jsx>{styles}</style>
    </div>
  ) : null;
};

export default AppliedFiltersTag;
