import { request, requestAPI } from '../../../../utils/request';
import {
  caresRequest,
  caresSuccess,
  caresFailure,
  setDisplayCares,
  careRequest,
  careSuccess,
  careFailure,
} from './actions';
import seasonToServices from '../../../helpers/seasonToServices';
import message from '../../../../utils/message';
import { Dispatch, FleetRentalData, GetState } from '../../../../@types';
import { CaresState } from './initialState';
import { fetchVehicleEventsForTrip } from '../vehicleEvents';

const getSortScore = (care: FleetRentalData): number => {
  const OFFSET = 1000000000000; // 1_000_000_000_000

  if (care.status === 'OPEN') {
    const today = new Date();
    return today.getTime() + OFFSET * 2;
  }

  if (care.status === 'IN_PROGRESS') {
    // @ts-ignore
    return new Date(care.tripStart).getTime() + OFFSET;
  }

  // care.status == 'CLOSED'
  // @ts-ignore
  return new Date(care.tripStart).getTime();
};

const fetchCares = (token: string) => (dispatch: Dispatch) => {
  dispatch(caresRequest());
  requestAPI(`/fleets/trips`, { type: 'serviceTrip' })
    .then((res) => {
      const sortedCares = res?.trips?.sort(
        (careA: FleetRentalData, careB: FleetRentalData) =>
          getSortScore(careB) - getSortScore(careA)
      );
      const seasonedCare = seasonToServices(sortedCares || []);
      dispatch(setDisplayCares(seasonedCare));
      dispatch(caresSuccess(seasonedCare));
    })
    .catch((err) => {
      dispatch(caresFailure(err));
      message.error(err.message);
    });
};

const shouldFetchCares = (state: CaresState) => !state.loading;

export const fetchCaresIfNeeded = (cookieToken: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchCares(getState().userData.cares)) {
      return dispatch(
        fetchCares(getState()?.userData.user?.token || cookieToken)
      );
    } else {
      return Promise.resolve();
    }
  };
};

export const createService =
  (
    token: string,
    service: { plate: any; location: any; serviceTypes: any; finishBy: any },
    callback: { (): (dispatch: any, getState: GetState) => any; (): void }
  ) =>
  () => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/services`);
    url.searchParams.append('plate', service.plate.toString());
    url.searchParams.append('location', service.location.toString());
    url.searchParams.append('finishBy', service.finishBy.toISOString());
    service.serviceTypes.map((service: string) =>
      url.searchParams.append('serviceTypes', service.toString())
    );
    request(url, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
    })
      .then(() => {
        message.success('Service created successfully');
        callback();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

export const fetchCareById = (careId) => (dispatch: Dispatch) => {
  dispatch(careRequest());
  requestAPI(`/fleets/trips/${careId}`)
    .then((care) => {
      if (care.ok === undefined) {
        dispatch(careSuccess(care));
        if (care?.carId) {
          const { id, carId, reservedAt, tripEnd } = care;
          dispatch(
            fetchVehicleEventsForTrip(carId, id, reservedAt, tripEnd, true)
          );
        }
      }
    })
    .catch((err) => {
      dispatch(careFailure(err));
      message.error(err.message);
    });
};
